import React, { useEffect, useState } from 'react';
import Navbar from '../../../../components/Navbar';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries } from '@tanstack/react-query';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Loaders from '../../../../components/Loaders';
import { useForm } from 'react-hook-form';

export default function RoleAssign() {

    const params = useParams();
    const { register, handleSubmit, } = useForm();
    const [allPermission, setAllPermission] = useState([])
    const [permissionChecked, setPermissionChecked] = useState([]);
    const [rolePermissionSet, setRolePermissionSet] = useState(false)

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        handlePermission();
    }, [])

    const onSubmit = (data) => {
        mutate(data);
    };

    const postData = async (data) => {

        const response = axiosPrivate.put(`role-permission/update/${params.id}`, {
            role_id: params.id,
            employee_permission_id: data.employee_permission_id
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/role'
                }, 1000)
            }
        }
    })



    const handlePermission = async () => {
        const res = await axiosPrivate.get(`permission/all`);
        setAllPermission(res?.data?.data)
        return res;
    };

    const handleRolePermission = async () => {
        const res = await axiosPrivate.get(`role-permission/show/${params.id}`);
        return res;
    };

    const [rolePermission] = useQueries({
        queries: [
            {
                queryKey: ['get-role-permission'],
                queryFn: handleRolePermission,
                staleTime: 0,
                cacheTime: 0
            },
        ],
    });


    // Update permissionChecked based on rolePermission data
    useEffect(() => {
        if (rolePermission.data && rolePermission.data.data && !rolePermissionSet) {

            const rolePermissions = rolePermission.data.data.data;
            const updatedPermissionChecked = {};
            rolePermissions.forEach(role => {
                updatedPermissionChecked[role.employee_permission_id] = true;
            });
            setPermissionChecked(updatedPermissionChecked);
            setRolePermissionSet(true)
        }
    }, [rolePermission?.data]);

    // Define an onChange handler for the checkboxes
    const handleCheckboxChange = (permissionId) => {
        setPermissionChecked(prevState => ({
            ...prevState,
            [permissionId]: !prevState[permissionId],

        }));
    };

    return (
        <>

            <Navbar title="Assign Permission" />
            {!allPermission.length ? <div className="d-flex justify-content-center align-items-center">
                <Loaders />
            </div> : <div className="container card my-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h4>Permission</h4>
                    <div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col" className='text-start w-50'>Permission name</th>
                                    <th scope="col" className='text-center '>Permission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allPermission?.map(permission => {
                                    const isChecked = permissionChecked[permission.id] || false;

                                    return (
                                        <tr>
                                            <td className='text-start'> <label className="form-check-label w-25" htmlFor={`flexCheckDefault_${permission.id}`}>
                                                {permission.name}
                                            </label></td>
                                            <td className='text-center'> <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={permission.id}
                                                id={`flexCheckDefault_${permission.id}`}
                                                checked={isChecked}
                                                {...register('employee_permission_id')}
                                                onChange={() => handleCheckboxChange(permission.id)}
                                            /></td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <button className="btn btn-login w-25 float-end"  > submit </button>
                </form>
            </div>}
        </>
    );
}
