
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Loaders from '../components/Loaders';

const PrivateRoute = ({ children }) => {
    const { auth, loading } = useAuth();
    const location = useLocation();
    if (loading) {
        return <div className="d-flex justify-content-center align-items-center">
            <Loaders />
        </div>
    }
    return auth ? children : < Navigate to={{ pathname: '/admin', state: { from: location } }
    } replace />
};

export default PrivateRoute;