import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth';
import Loaders from '../components/Loaders';


export default function PermissionRoute({ children }) {
    const [isAuthorised, setIsAuthorised] = useState(undefined)
    const { role } = useAuth();

    const location = useLocation();

    useEffect(() => {
        if (role?.length) {
            const path = location.pathname.split('/')

            const lastPath = path[3]
            if (path?.length === 3) {
                setIsAuthorised(true)
            }

            else if (path?.length > 3) {
                const permission = role.find((item) => item?.employee_permission?.name === lastPath)
                if (permission) {
                    setIsAuthorised(true)
                }
                else {
                    setIsAuthorised(false)
                }
            }
        }

    }, [role])




    if (isAuthorised === true) {
        return children
    }
    else if (isAuthorised === false) {
        return <Navigate to={{ pathname: '/401', state: { from: location } }
        } replace />
    }

    else {
        return <Loaders />
    }
}
