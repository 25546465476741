import React from 'react';
import './Login.css';
import welcome from '../../assets/welcome.svg';
import slikekspressen from '../../assets/Slikekspressen.png';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const postData = async (data) => {
    const response = axios.post('packer/login', {
        email: data.email,
        password: data.password
    });
    return response;
}

const Login = () => {
    const { setAuth, setUserInfo, setIsActive, isActive, role } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/admin/dashboard';
    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            const accessToken = successData.data?.data.token;
            const employee = successData.data?.data.employee;
            const is_active = successData.data?.data.employee.is_active
            const role_id = successData.data?.data.employee.role_id

            if (is_active == true && role_id) {
                setUserInfo(employee);
                setIsActive(is_active);
                setAuth(accessToken, isActive, employee);
                // setRole(employee.role);
                localStorage.setItem('accessToken', accessToken);
            }
        }
    })
    if (isActive == true && role) {
        navigate(from, { replace: true });
    }

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        mutate(data);
    };

    return (
        <div className='login-fullwidth'>
            <div className="container container-login">
                <div className="login-content">
                    <form className='form-login' onSubmit={handleSubmit(onSubmit)}>
                        <img src={slikekspressen} alt="brand-logo" />
                        <h2 className="title">Log In</h2>
                        <div className="input-div one">
                            <div className="div">
                                <div className="form-group form-group-login">
                                    <label htmlFor="exampleFormControlInput1 " className="form-label float-start">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control form-control-login"
                                        id="exampleFormControlInput1"
                                        placeholder="Email"
                                        name="email"
                                        {...register("email")}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="input-div pass my-3">
                            <div className="div">
                                <div className="form-group form-group-login">
                                    <label htmlFor="exampleFormControlInput1" className="form-label float-start">Password</label>
                                    <input
                                        type="password"
                                        className="form-control form-control-login"
                                        id="exampleFormControlInput1"
                                        placeholder="Password"
                                        name="password"
                                        {...register("password")}
                                    />
                                </div>
                            </div>
                        </div>
                        <input type="submit" className="btn btn-login" value="Login" />
                    </form>
                </div>
                <div className="img">
                    <img src={welcome} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Login;