import  { useEffect } from 'react';
import { axiosPrivate } from '../api/axios';
import useAuth from './useAuth';

const useAxiosPrivate = () => {
    const { auth } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = 'Bearer ' + auth;
                }
                return config;
            }, error => {
                return Promise.reject(error);
            }
        )
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async error => {    
                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.response.eject(responseIntercept);
            axiosPrivate.interceptors.request.eject(requestIntercept);
        }

    }, [auth])

    return axiosPrivate;
};

export default useAxiosPrivate;