import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function UpdateGIftCardImage() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch, getValues } = useForm();
    const [giftCardImage, setGiftCardImage] = useState(null);
    const [image, setImage] = useState(null);
    const params = useParams();
    const postData = async (data) => {
        const makeData = {
            title: data.title,
            is_active: data.is_active,
            image: giftCardImage ? giftCardImage : null,
            price: data.price,
            country_code: 'dk',
            currecny_code: data.currency_code,
            language_code: data.language_code
        }


        const response = axiosPrivate.post(`gift-image/create-new`, makeData);
        return response;
    }

    const handleGiftCard = () => {
        const res = axiosPrivate.get(`gift-image/details/${params.id}`)
        return res;
    }

    const { data: giftCard } = useQuery({
        queryKey: ['get-country'],
        queryFn: handleGiftCard
    })

    useEffect(() => {
        if (giftCard?.data?.data) {
            Object.keys(giftCard.data.data).forEach((key) => {
                setValue(key, giftCard.data.data[key]);
            });

            setValue("is_active", giftCard?.data?.data?.is_active ? 1 : 0)
            setValue("language_code", giftCard?.data?.data?.language_code)
            setValue("currency_code", giftCard?.data?.data?.currency_code)
            setImage(giftCard?.data?.data?.image)

        }
    }, [giftCard, setValue])

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/gift-cards'
                }, 3000)
            }
        }
    })

    const handleFile = (event) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 10000000) {
            alert("File size should be less than 10MB");
            return;
        }
        const reader = new FileReader();


        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setGiftCardImage(reader?.result);
        };
    }


    const onSubmit = (data) => {
        mutate(data);
    }




    return (
        <>
            <Navbar
                title="Create a Gift Card"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="title"
                                    label="title"
                                    variant="outlined"
                                    placeholder="Enter Gift Card title"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("title")}
                                    error={!!errors?.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("is_active")}
                                        value={watch("is_active") !== undefined ? watch("is_active") : ""}
                                        label="Status"
                                        onChange={(event) => {
                                            setValue("is_active", parseInt(event.target.value, 10))
                                        }}
                                    >
                                        <MenuItem value={1}> Active</MenuItem>
                                        <MenuItem value={0}> Inactive</MenuItem>
                                    </Select> </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <img src={giftCardImage} alt='' height="100px" />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="price"
                                    label="price"
                                    variant="outlined"
                                    placeholder="Enter Gift Card price"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("price")}
                                    error={!!errors?.price}
                                    helperText={errors.price?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Image</label>
                                <input class="form-control" type="file" id="formFile" name="image" accept=".png,.jpg,.jpeg" onChange={handleFile} />
                                <p className='fs-6 fst-italic my-2 text-danger'>The image dimensions should be at least 1920 pixels in width and 1080 pixels in height</p>
                            </Grid>


                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Language</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("language_code")}
                                        value={watch("language_code")}
                                        label="Language"
                                        onChange={(event) => {
                                            setValue("language_code", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="en"> English</MenuItem>
                                        <MenuItem value="da"> Danish</MenuItem>
                                    </Select> </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Currency Code</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("currency_code")}
                                        value={watch("currency_code")}
                                        label="Currency Code"
                                        onChange={(event) => {
                                            setValue("currency_code", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="DKK"> DKK</MenuItem>
                                        <MenuItem value="EUR"> EURO</MenuItem>
                                    </Select> </FormControl>
                            </Grid>


                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
