import { createContext, useEffect, useState } from 'react'
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null)
    const [userInfo, setUserInfo] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState([])


    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            setAuth(accessToken);
        }
        setLoading(false);
    }, []);
    // data get using fetch 
    useEffect(() => {
        if (auth) {
            const handleCustomer = async () => {
                const res = await fetch(`${process.env.REACT_APP_API_URL}employee/login/details`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth}`,
                        'Content-Type': 'application/json',
                        'withCredentials': false
                    }
                });
                const data = await res.json();
                setRole(data?.data?.role?.assgin_permission)
                setIsActive(data?.data?.is_active)
                setUserInfo(data?.data)
            }

            handleCustomer();
        }


    }, [auth])



    return (
        <AuthContext.Provider value={{ auth, isActive, userInfo, setAuth, setLoading, setUserInfo, setIsActive, loading, role, setRole }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
