import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ title, btnTitle, btnLink }) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-custom">
            <div className="container-fluid">
                <h1 className="navbar-brand nav-text" >{title}</h1>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                {btnTitle && <div className="float-end">
                    <Link to={btnLink}>
                        <button className="btn-warning">
                            {btnTitle}
                        </button>
                    </Link>

                </div>}
            </div>
        </nav>
    );
};

export default Navbar;