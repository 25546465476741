import React from 'react'
import Navbar from '../../../components/Navbar'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Container } from '../../../components/Container';
import { useQuery } from '@tanstack/react-query';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import Loader from '../../../components/Loaders';

export default function CategoryMenu() {
    const [isVertical, setIsVertical] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const handleVerticalCategories = () => {
        const res = axiosPrivate.get(`/menu-item`)
        return res;
    };
    const { data: allVerticalCategories = [], isLoading } = useQuery({
        queryKey: ['get-VerticalCategories'],
        queryFn: handleVerticalCategories,
        cacheTime: 5000,
    })

    const handleOnMenu = () => {
        setIsVertical(!isVertical)
    };

    return (
        <>
            <Navbar
                title={isVertical ? 'Top Category Menu' : 'Vertical Category Menu'}
                btnTitle="Create Category Menu"
                btnLink="/admin/dashboard/category-menu/create"

            />



            {
                isLoading && (
                    <div className="d-flex justify-content-center align-items-center">
                        <Loader />
                    </div>
                )
            }
            {allVerticalCategories?.data?.data &&
                <div className='container'>
                    <button className='btn btn-info  my-3 float-end' onClick={() => handleOnMenu()}><i className="fa-solid fa-rotate-left me-2"></i> Chanage to {isVertical ? 'Vertical' : 'Top'} Categories</button>
                    <DndProvider backend={HTML5Backend}>
                        {
                            isVertical ? <Container top={allVerticalCategories?.data?.data?.top} isTop={true} /> :
                                <Container top={allVerticalCategories?.data?.data?.vertical} isTop={false} />
                        }
                    </DndProvider>
                </div>
            }


        </>
    )
}
