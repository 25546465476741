import { CKEditor } from '@ckeditor/ckeditor5-react';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, Chip, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Radio, Tooltip, RadioGroup, Select, Snackbar, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeleteForever } from '@mui/icons-material';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
}));


function getSteps() {
    return [
        "Candy information",
        "Ingredients information",
        "Pricing Details",
        "Image",
    ];
}
const BasicForm = () => {
    const [product_type, setProductType] = React.useState('');
    const axiosPrivate = useAxiosPrivate();

    const handleChange = (event) => {
        setProductType(prev => prev = event.target.value);
    };

    const handleProductType = () => {
        const res = axiosPrivate.get(`product-type/all`)
        return res;
    };

    const { data: allProductType = [] } = useQuery({
        queryKey: ['get-ProductType'],
        queryFn: handleProductType,
        cacheTime: 5000,
    })

    const {
        getValues,
        control,
        setValue,
        formState: { errors },
    } = useFormContext();

    const product_type_data = getValues('product_type');

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>

                <Controller
                    control={control}
                    name="title"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <TextField
                            id="candy-name"
                            label="Candy Name"
                            variant="outlined"
                            placeholder="Enter Candy Name"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                            error={!!errors?.title}
                            helperText={errors.title?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="subtitle"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <TextField
                            id="subtitle"
                            label="Subtitle"
                            variant="outlined"
                            placeholder="Enter Subtitle"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                            error={Boolean(errors?.subtitle)}
                            helperText={errors.subtitle ? errors.subtitle?.message : ""}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="short_code"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <TextField
                            id="Short-Code"
                            label="Candy Short Code"
                            variant="outlined"
                            placeholder="Enter Short Code"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                            error={Boolean(errors?.short_code)}
                            helperText={errors.short_code ? errors.short_code?.message : ""}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="vendor"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <TextField
                            id="vendor"
                            label="Vendor"
                            variant="outlined"
                            placeholder="Enter vendor"
                            margin="normal"
                            fullWidth={true}
                            {...field}
                            error={Boolean(errors?.vendor)}
                            helperText={errors.vendor ? errors.vendor?.message : ""}
                        />
                    )}
                />

            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="product_supplier"
                    render={({ field }) => (
                        <>
                            <FormControl fullWidth={true}>
                                <InputLabel id="demo-simple-select-label">Product Supplier</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="product_supplier"
                                    value={field.value}
                                    label="Product Supplier"
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                    }}
                                >
                                    <MenuItem value="Cloetta">Cloetta</MenuItem>
                                    <MenuItem value="Sugro">Sugro</MenuItem>
                                    <MenuItem value="Dankonfekt">Dankonfekt</MenuItem>
                                    <MenuItem value="Scan choko">Scan choko</MenuItem>
                                    <MenuItem value="Toms">Toms</MenuItem>
                                </Select>
                            </FormControl>
                        </>

                    )}
                />

            </Grid>
            <Grid item xs={6} >
                <Controller
                    control={control}
                    name="alley_side"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <>
                            <Typography textAlign="left" >
                                {errors.alley_side ? (
                                    <Typography color="error"> Alley Side</Typography>
                                )
                                    : <Typography>Alley Side</Typography>
                                }
                            </Typography>
                            {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                {...field}
                                fullWidth={true}
                                error={Boolean(errors?.alley_side)}
                            >
                                <FormControlLabel value="left" control={<Radio />} label="Alley Left" />
                                <FormControlLabel value="right" control={<Radio />} label="Alley Right" />

                            </RadioGroup>
                            <FormHelperText error={true} >{errors.alley_side ? errors.alley_side?.message : ""}</FormHelperText>

                        </>

                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="product_type"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <>
                            <FormControl fullWidth={true}>
                                <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="product_type"
                                    value={field.value}
                                    label="Product Type"
                                    onChange={(event) => {
                                        field.onChange(event.target.value)
                                        handleChange(event)
                                    }}
                                    error={Boolean(errors?.product_type)}
                                    helperText={errors.product_type ? errors.product_type?.message : ""}
                                >

                                    {
                                        allProductType?.data?.data?.map((item, index) => {
                                            return <MenuItem key={index} value={item?.product_type_code}>
                                                <Tooltip title={item.description} arrow>
                                                    <span>{item.name}</span>
                                                </Tooltip>
                                            </MenuItem>
                                        })

                                    }

                                </Select>
                                <FormHelperText error={true} >{errors.product_type ? errors.product_type?.message : ""}</FormHelperText>
                            </FormControl>
                        </>

                    )}
                />

            </Grid>

            {
                (product_type_data === "Gift Items" || product_type === "Gift Items") &&
                <Grid item xs={6}>
                    <Controller
                        control={control}
                        name="product_variant"
                        rules={{ required: "this field is required." }}
                        render={({ field }) => (
                            <>
                                <Typography textAlign="left" >
                                    {errors.product_variant ? (
                                        <Typography color="error"> Product variant (Gift)</Typography>
                                    )
                                        : <Typography>Product variant (Gift)</Typography>
                                    }
                                </Typography>
                                {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="product_variant"
                                    {...field}
                                    fullWidth={true}
                                    error={Boolean(errors?.product_variant)}
                                    helperText={errors.title?.message}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="Box" />
                                    <FormControlLabel value="2" control={<Radio />} label="Bottle" />
                                </RadioGroup>
                                <FormHelperText error={true} >{errors.product_variant ? errors.product_variant?.message : ""}</FormHelperText>
                            </>

                        )}
                    />
                </Grid>
            }

            {
                (product_type_data === "Upsell product" || product_type === "Upsell product" || product_type_data === "Other products" || product_type === "Other products" || product_type_data === "Gift Items" || product_type === "Gift Items") &&
                <>
                    {product_type_data == "Gift Items" || product_type == "Gift Items" ?<> </> : <Grid item xs={6}></Grid>}
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="product_description_en"
                            rules={{ required: "this field is required." }}
                            render={({ field }) => (
                                <>
                                    <Typography textAlign="left" >
                                        {errors.product_description_en ? (
                                            <Typography color="error"> Product Description  (En)</Typography>
                                        )
                                            : <Typography>Product Description  (En)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} onBlur={(e) => setValue("product_description_en", e.target.value)} />
                                    <FormHelperText error={true} >{errors.product_description_en ? errors.product_description_en?.message : ""}</FormHelperText>
                                </>

                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Controller
                            control={control}
                            name="product_description_en"
                            rules={{ required: "this field is required." }}
                            render={({ field }) => (
                                <>
                                    <Typography textAlign="left" >
                                        {errors.product_description_da ? (
                                            <Typography color="error"> Product Description For hot Deals (Da)</Typography>
                                        )
                                            : <Typography>Product Description For Hot Deals (Da)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} onBlur={(e) => setValue("product_description_da", e.target.value)} />
                                    <FormHelperText error={true} >{errors.product_description_da ? errors.product_description_da?.message : ""}</FormHelperText>
                                </>

                            )}
                        />
                    </Grid>
                </>

            }
        </Grid>
    );
};
const ContactForm = () => {

    const [tags, setTags] = useState([])
    const [ingredients, setIngredients] = useState([])
    const { control, setValue, getValues, setFocus } = useFormContext();
    const axiosPrivate = useAxiosPrivate();
    const tagData = getValues("tagsFields")
    const tagsData = tags?.length ? tags : tagData?.tags

    const handleAllergic = () => {
        const res = axiosPrivate.get(`product-adjustment/all`)
        return res;
    };

    function handleDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const newIngredients = Array.from(ingredients);
        const [removed] = newIngredients.splice(sourceIndex, 1);
        newIngredients.splice(destinationIndex, 0, removed);
        setIngredients(newIngredients);
        setValue("ingredients_name", newIngredients)
    }

    const { data: allAllergic = [] } = useQuery({
        queryKey: ['get-allergic'],
        queryFn: handleAllergic,
        cacheTime: 5000,
    })

    useEffect(() => {
        const ingredient = getValues("ingredients_name")
        setIngredients(ingredient || [])
        const tagFiled = getValues("tagsFields")
        setTags(tagFiled?.tags || [])
    }, [getValues("ingredients_name"), getValues("tagsFields")])


    const handleAddTags = (value, e) => {
        e.preventDefault()
        if (value.trim() !== '') {
            setTags(prev => {
                setValue("tagsFields", { tags: [...prev, value] })
                return [...prev, value]
            })

            setValue("tags", "")

            setTimeout(() => {
                setFocus("tagsFiled")
            }, 300);
        }

    }

    const handleAddIngredient = (value, e) => {
        e.preventDefault()
        if (value.trim() !== '') {
            setIngredients(prev => {
                setValue("ingredients_name", [...prev, value])
                return [...prev, value]
            })

            setValue("ingredients_names", "")
            setTimeout(() => {
                setFocus("ingredients_names")
            }, 300);
        }

    }


    const handleDeleteClick = (index) => {
        const removeValue = tags.filter((tag, i) => index !== i)
        setTags(removeValue)
        setValue("tagsFields", { tags: removeValue })
    }
    const handleDeleteIngredient = (index) => {
        const removeValue = ingredients.filter((ingredient, i) => index !== i)
        setIngredients(removeValue)
        setValue("ingredients_name", removeValue)
    }
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="body_html"
                        render={({ field }) => (
                            <>
                                <Typography textAlign="left" >
                                    Product Description
                                </Typography>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={field.value}
                                    className="m-3"
                                    onBlur={(event, editor) => {
                                        field.onChange(editor.getData());
                                    }}
                                />
                            </>

                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div className='d-flex'>
                        <Grid item xs={6}>
                            <Grid item xs={11}>
                                <Controller
                                    control={control}
                                    name="ingredients_names"
                                    render={({ field }) => (
                                        <div className='d-flex'>
                                            <TextField
                                                id="ingredients_names"
                                                label="Ingredients Name"
                                                variant="outlined"
                                                placeholder="Ingredients Name"
                                                fullWidth={true}
                                                onKeyDown={(e) => e.keyCode === 13 && handleAddIngredient(field.value, e)}
                                                margin="normal"
                                                {...field}
                                            />


                                            <Button size="small" color="secondary" variant="contained" sx={{
                                                marginTop: "13px",
                                                marginBottom: "8px",
                                                marginLeft: "-15px",
                                            }} onClick={(e) => handleAddIngredient(field.value, e)
                                            } >
                                                Add
                                            </Button>
                                        </div>

                                    )}
                                />
                            </Grid>


                            <Grid item xs={11}>
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="droppable" direction="vertical">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {ingredients.map((ingredient, index) => (
                                                    <Draggable
                                                        key={`ingredient-${index}`}
                                                        draggableId={`ingredient-${index}`}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <Chip
                                                                label={ingredient}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                icon={
                                                                    <DeleteForever
                                                                        onClick={() => handleDeleteIngredient(index)}
                                                                        sx={{
                                                                            color: "white !important",
                                                                            cursor: "pointer",
                                                                            border: "1px solid red",
                                                                            backgroundColor: "red",
                                                                            borderRadius: "50%",
                                                                            padding: "4px",
                                                                            "&:hover": {
                                                                                color: "red !important",
                                                                                backgroundColor: "white",
                                                                                cursor: "pointer",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                sx={{
                                                                    marginLeft: "4px",
                                                                    marginRight: "4px",
                                                                    marginY: "5px",
                                                                    padding: "10px",
                                                                    backgroundColor: "lightpurple",
                                                                    display: 'flex',
                                                                    color:
                                                                        allAllergic &&
                                                                            allAllergic?.data?.data?.some(
                                                                                (item) => item.name === ingredient
                                                                            )
                                                                            ? "black"
                                                                            : "inherit",
                                                                    fontWeight:
                                                                        allAllergic &&
                                                                            allAllergic?.data?.data?.some(
                                                                                (item) => item.name === ingredient
                                                                            )
                                                                            ? "bold"
                                                                            : "normal",
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            />
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                            <Grid item xs={11}>
                                <Controller
                                    control={control}
                                    name="tags"
                                    render={({ field }) => (
                                        <div className='d-flex'>
                                            <TextField
                                                id="tags"
                                                label="Tags"
                                                variant="outlined"
                                                placeholder="tags"
                                                fullWidth={true}

                                                margin="normal"
                                                onKeyDown={(e) => e.keyCode === 13 && handleAddTags(field.value, e)}
                                                {...field}
                                            />


                                            <Button size="small" color="secondary" variant="contained" sx={{
                                                marginTop: "13px",
                                                marginBottom: "8px",
                                                marginLeft: "-15px",
                                            }} onClick={(e) => handleAddTags(field.value, e)
                                            } >
                                                Add
                                            </Button>
                                        </div>

                                    )}
                                />
                            </Grid>

                            <Grid item xs={11}>
                                {tagsData && tagsData?.map((tag, index) => {
                                    return <Chip key={index} label={tag}
                                        icon={<DeleteForever onClick={() => handleDeleteClick(index)} sx={{
                                            color: "white !important",
                                            cursor: "pointer",
                                            border: "1px solid red",
                                            backgroundColor: "red",
                                            borderRadius: "50%",
                                            padding: "4px",
                                            "&:hover": {
                                                color: "red !important",
                                                backgroundColor: "white",
                                                cursor: "pointer",
                                            }
                                        }} />} sx={{
                                            marginLeft: "4px",
                                            marginRight: "4px",
                                            padding: "10px",
                                            display: 'flex',
                                            marginY: "5px",
                                            backgroundColor: "lightpurple",
                                            color: "black",
                                        }} />
                                }
                                )}

                            </Grid>



                        </Grid>

                        <Grid item xs={6}>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="energy_kj_100g"
                                    render={({ field }) => (
                                        <TextField
                                            id="energy_kj_100g"
                                            label="Energy (kj 100g)"
                                            variant="outlined"
                                            placeholder="Enter Energy (kj 100g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="energy_kcal_100g"
                                    render={({ field }) => (
                                        <TextField
                                            id="energy_kcal_100g"
                                            label="Energy (kcal 100g)"
                                            variant="outlined"
                                            placeholder="Enter Energy (kcal 100g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="fat_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="fat_g"
                                            label="Fat (g)"
                                            variant="outlined"
                                            placeholder="Enter Fat (g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="of_which_saturates_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="of_which_saturates_g"
                                            label="Of which Saturates (g)"
                                            variant="outlined"
                                            placeholder="Enter Of Which Saturates (g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="carbohydrate_g_100g"
                                    render={({ field }) => (
                                        <TextField
                                            id="carbohydrate_g_100g"
                                            label="Carbohydrate (g 100g)"
                                            variant="outlined"
                                            placeholder="Enter Carbohydrate (g 100g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="of_which_sugars_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="of_which_sugars_g"
                                            label="Of which sugars (g)"
                                            variant="outlined"
                                            placeholder="Enter Of which sugars (g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="protein_g_100g"
                                    render={({ field }) => (
                                        <TextField
                                            id="protein_g_100g"
                                            label="Protein (g 100g)"
                                            variant="outlined"
                                            placeholder="Enter Protein (g 100g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="salt_g_100g"
                                    render={({ field }) => (
                                        <TextField
                                            id="salt_g_100g"
                                            label="Salt (100g)"
                                            variant="outlined"
                                            placeholder="Enter Salt (100g)"
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="of_which_starch_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="of_which_starch_g"
                                            label="Of which starch (g)"
                                            variant="outlined"
                                            placeholder="Enter Of which starch (g)"
                                            hidden
                                            fullWidth={true}
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="sodium_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="sodium_g"
                                            label="Sodium (g)"
                                            variant="outlined"
                                            placeholder="Enter sodium (g)"
                                            fullWidth={true}
                                            hidden
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="fibre_aoac_g"
                                    render={({ field }) => (
                                        <TextField
                                            id="fibre_aoac_g"
                                            label="Fibre aoac (g)"
                                            variant="outlined"
                                            placeholder="Enter Fibre aoac (g)"
                                            fullWidth={true}
                                            hidden
                                            margin="normal"
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                    </div>
                </Grid>


            </Grid>
        </>
    );
};
const PersonalForm = () => {
    const { control, formState: { errors }, } = useFormContext();
    const axiosPrivate = useAxiosPrivate();

    const validateUnique = async (value) => {
        const response = await axiosPrivate.post("candy/check-sku", { "sku": value });
        if (!response.data.success) {
            return "SKU already exists";
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="price"
                    rules={{ required: "Price is required" }}
                    render={({ field }) => (
                        <TextField
                            id="price"
                            label="Candy Price"
                            variant="outlined"
                            placeholder="Enter Candy Price"
                            type="decimal"
                            fullWidth={true}
                            error={!!errors.price}
                            helperText={errors.price?.message}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="weight"
                    rules={{ required: "Weight is required" }}
                    render={({ field }) => (
                        <TextField
                            id="weight"
                            label="Candy Total Weight "
                            variant="outlined"
                            placeholder="Enter Candy Total Weight"
                            fullWidth={true}
                            error={!!errors.weight}
                            helperText={errors.weight?.message}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="amount"
                    rules={{ required: "Amount is required" }}
                    render={({ field }) => (
                        <TextField
                            id="amount"
                            label="Candy Pieces"
                            variant="outlined"
                            placeholder="Enter Candy Pieces"
                            fullWidth={true}
                            error={!!errors.amount}
                            helperText={errors.amount?.message}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="sku"
                    rules={{
                        required: "This field is required.",
                        validate: validateUnique
                    }}
                    render={({ field }) => (
                        <TextField
                            id="sku"
                            label="Sku"
                            variant="outlined"
                            placeholder="Enter sku"
                            onBlur={field.onBlur}
                            fullWidth={true}
                            margin="normal"
                            {...field}
                            error={!!errors?.sku}
                            helperText={errors.sku?.message}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="product_sku_weight"
                    render={({ field }) => (
                        <TextField
                            id="product_sku_weight"
                            label="Product SKU weight"
                            variant="outlined"
                            placeholder="Enter Product SKU weight"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="product_sku_weight_level"
                    render={({ field }) => (
                        <TextField
                            id="product_sku_weight_level"
                            label="Product SKU weight level"
                            variant="outlined"
                            placeholder="Enter Product SKU weight level"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="product_sku_ideal_weight_level"
                    render={({ field }) => (
                        <TextField
                            id="product_sku_ideal_weight_level"
                            label="Product SKU ideal weight level"
                            variant="outlined"
                            placeholder="Enter Product ideal SKU weight level"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="cost"
                    render={({ field }) => (
                        <TextField
                            id="cost"
                            label="Cost Per Item"
                            variant="outlined"
                            placeholder="Enter Cost Per Item"
                            fullWidth={true}
                            margin="normal"
                            {...field}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="currency"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <>
                            <Typography textAlign="left" >
                                {

                                    errors.currency ? (
                                        <Typography color="error"> Currency</Typography>
                                    )
                                        : <Typography>Currency</Typography>

                                }

                            </Typography>
                            {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-labelss"
                                name="currency"
                                {...field}
                                fullWidth={true}
                                error={Boolean(errors?.currency)}
                                helperText={errors.currency ? errors.currency?.message : ""}
                            >
                                <FormControlLabel value="DKK" control={<Radio />} label="DKK" />
                                <FormControlLabel value="USD" control={<Radio />} label="USD" />
                            </RadioGroup>
                            <FormHelperText error={Boolean(errors?.currency)}>{errors.currency ? errors.currency?.message : ""}</FormHelperText>
                        </>

                    )}
                />
            </Grid>

            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="status"
                    rules={{ required: "this field is required." }}
                    render={({ field }) => (
                        <>
                            <Typography textAlign="left" >

                                {

                                    errors.status ? (
                                        <Typography color="error"> Product Status</Typography>
                                    )
                                        : <Typography>Product Status</Typography>

                                }

                            </Typography>
                            {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="status"
                                {...field}
                                fullWidth={true}
                                error={Boolean(errors?.status)}
                                helperText={errors.status ? errors.status?.message : ""}
                            >
                                <FormControlLabel value="active" control={<Radio />} label="Active" />
                                <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                                <FormControlLabel value="archived" control={<Radio />} label="Archived" />
                                {/* <FormControlLabel value="notAssign" control={<Radio />} label="Not Assigned" /> */}
                            </RadioGroup>
                            <FormHelperText error={Boolean(errors?.currency)}>{errors.currency ? errors.currency?.message : ""}</FormHelperText>
                        </>

                    )}
                />
            </Grid>
        </Grid>
    );
};
const PaymentForm = () => {

    const { control, setValue, getValues } = useFormContext();
    const [base64, setBase64] = useState('');


    const handleImageInputChange = (event) => {
        const imageFile = event.target.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();

        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            setBase64(reader.result);
            const base64Value = reader.result.replace(/^data:image\/[a-z]+;base64,/, '');
            setValue("attachment", base64Value);
            setValue("filename", imageFile.name);
        };
    };


    return (
        <>
            <Controller
                control={control}
                name="attachments"
                render={({ field }) => (
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <input
                            type="file"
                            accept="image/*"
                            className='form-control'
                            {...field}
                            onChange={handleImageInputChange}
                        />


                        {base64 && (
                            <img
                                src={base64}
                                alt="converted-to-base64"
                                name="attachments"
                                value={field.value}
                                style={{ width: 200, height: 200 }}
                            />
                        )}
                    </div>
                )}
            />
        </>
    );
};
function getStepContent(step) {
    switch (step) {
        case 0:
            return <BasicForm />;
        case 1:
            return <ContactForm />;
        case 2:
            return <PersonalForm />;
        case 3:
            return <PaymentForm />;
        default:
            return "Unknown step";
    }
}

const MultiStepForm = () => {

    const classes = useStyles();
    const axiosPrivate = useAxiosPrivate();
    const methods = useForm({
        defaultValues: {
            title: "",
            subtitle: "",
            short_code: "",
            alley_side: "",
            body_html: "",
            product_type: "",
            product_variant: "",
            product_description_da: "",
            product_description_en: "",
            tags: "",
            vendor: "",
            price: "",
            weight: "",
            amount: "",
            sku: "",
            product_sku_weight: "",
            product_sku_weight_level: "",
            product_sku_ideal_weight_level: "",
            currency: "",
            status: "",
            cost: "",
            attachment: "",
            product_supplier: "",
            energy_kj_100g: "",
            energy_kcal_100g: "",
            protein_g_100g: "",
            carbohydrate_g_100g: "",
            of_which_sugars_g: "",
            of_which_starch_g: "",
            of_which_saturates_g: "",
            filename: "",
            sodium_g: "",
            fat_g: "",
            fibre_aoac_g: "",
            salt_g_100g: "",
            tagsFields: [
            ],
            tagTest: [],
            ingredients_name: []
        },
    });
    const [activeStep, setActiveStep] = useState(0);
    const [skippedSteps, setSkippedSteps] = useState([]);
    const steps = getSteps();

    const isStepFalied = () => {
        return Boolean(Object.keys(methods.formState.errors).length);
    };
    const isStepSkipped = (step) => {
        return skippedSteps.includes(step);
    };

    const postData = async (data) => {
        if (data?.attachment) {
            const productData = {
                "product": {
                    "title": data?.title,
                    "subtitle": data?.subtitle,
                    "short_code": data?.short_code,
                    "alley_side": data?.alley_side,
                    "body_html": data?.body_html,
                    "vendor": data?.vendor,
                    "product_type": data?.product_type,
                    "product_supplier": data?.product_supplier,
                    "product_variant": data?.product_variant,
                    "handle": data?.title,
                    "status": data?.status,
                    "published_scope": "web",
                    "product_sku_weight": data?.product_sku_weight,
                    "cost": parseFloat(data?.cost),
                    "product_sku_weight_level": data?.product_sku_weight_level,
                    "product_sku_ideal_weight_level": data?.product_sku_ideal_weight_level,
                    "amount": data?.amount,
                    "currency": data?.currency,
                    "product_description_hot_deals": {
                        "da": data?.product_description_da,
                        "en": data?.product_description_en
                    },
                    "tags": data.tagsFields?.tags ? (data.tagsFields?.tags.map((item) => item)?.join(",")) : "",
                    "variants": [
                        {
                            "title": "Default Title",
                            "price": data.price,
                            "sku": data.sku,
                            "position": 1,
                            "cost": parseFloat(data.cost),
                            "inventory_policy": "continue",
                            "compare_at_price": null,
                            "fulfillment_service": "manual",
                            "inventory_management": null,
                            "option1": "Default Title",
                            "option2": null,
                            "option3": null,
                            "taxable": true,
                            "barcode": "",
                            "grams": data.product_sku_weight,
                            "image_id": null,
                            "weight": data.weight,
                            "weight_unit": "g",
                            "requires_shipping": true
                        }
                    ],

                    "ingredients": {
                        "name": data?.ingredients_name ? (data.ingredients_name.map((item) => item)?.join(",")) : "",
                        "energy_kj_100g": data?.energy_kj_100g,
                        "energy_kcal_100g": data?.energy_kcal_100g,
                        "protein_g_100g": data?.protein_g_100g,
                        "carbohydrate_g_100g": data?.carbohydrate_g_100g,
                        "of_which_sugars_g": data?.of_which_sugars_g,
                        "of_which_starch_g": data?.of_which_starch_g,
                        "sodium_g": data?.sodium_g,
                        "fat_g": data?.fat_g,
                        "of_which_saturates_g": data?.of_which_saturates_g,
                        "fibre_aoac_g": data?.fibre_aoac_g,
                        "salt_g_100g": data?.salt_g_100g,
                    }
                },
                "product_image": {
                    "image": {
                        "position": 1,
                        "metafields": [
                            {
                                "key": "new",
                                "value": "newvalue",
                                "type": "single_line_text_field",
                                "namespace": "global"
                            }
                        ],
                        "attachment": data?.attachment,
                        "filename": data?.filename
                    }
                }
            }
            const response = axiosPrivate.post('candy/create-new', productData);
            return response;
        }
        else {
            alert('Image File require')
        }
    }
    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/product'
                }, 3000)
            }
            if (successData?.data?.success == false) {
                alert('Something went wrong')
            }
        },
        onError: (error) => {
            if (error) {
                alert('Something went wrong')
            }
        }

    })

    const handleNext = (data) => {
        if (activeStep == steps.length - 1) {
            mutate(data);
        } else {
            setActiveStep(activeStep + 1);
            setSkippedSteps(
                skippedSteps.filter((skipItem) => skipItem !== activeStep)
            );
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };


    return (
        <div>
            <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                    const labelProps = {};
                    const stepProps = {};

                    if (isStepFalied() && activeStep == index) {
                        labelProps.error = true;
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step {...stepProps} key={index}>
                            <StepLabel {...labelProps}>{step}</StepLabel>
                        </Step>
                    );
                })}

            </Stepper>

            {activeStep === steps.length ? (
                <Typography variant="h3" align="center">
                    Thank You
                </Typography>
            ) : (
                <>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(handleNext)}>
                            <Card variant="outlined" sx={{ marginTop: "20px", marginBottom: "20px", padding: "10px", }}>
                                {getStepContent(activeStep, methods)}
                            </Card>

                            <Card sx={{ padding: "10px" }}>
                                <Button
                                    className={`float-start ${classes.button}`}
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="contained" color="error"

                                >
                                    Back
                                </Button>
                                <Button
                                    className={`float-end ${classes.button}`}
                                    variant="contained"
                                    color={activeStep === steps.length - 1 ? "primary" : "success"}
                                    type={activeStep === steps.length - 1 && "submit"}
                                >
                                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                                </Button>
                            </Card>

                        </form>
                    </FormProvider>

                </>
            )}
        </div>
    );
};

export default MultiStepForm;