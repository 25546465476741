import React from 'react';
import Navbar from '../../../../components/Navbar';
import './ProductCreate.css';

import { Box, Container, CssBaseline, Paper } from '@mui/material';
import MultiStepForm from '../../../../components/MultiStepForm';
import theme from '../../../../components/theme';
import { ThemeProvider } from '@material-ui/styles';

const ProductCreate = () => {
    return (
        <div>
            <Navbar title="Create a new candy" />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container component={Box} p={4}>
                    <Paper component={Box} p={3}>
                        <MultiStepForm />
                    </Paper>
                </Container>
            </ThemeProvider>

        </div>
    );
};

export default ProductCreate;