import React from 'react'
import Navbar from '../../../../components/Navbar'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Grid, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loaders from '../../../../components/Loaders';

export default function UpdateCoupon() {
    const axiosPrivate = useAxiosPrivate();
    const [isCodeUnique, setIsCodeUnique] = useState(true);
    const [code, setCode] = useState()
    const params = useParams();
    const [ambassadorsDetails, setAmbassadorsDetails] = useState({});
    const checkCodeUnique = async (value) => {
        try {
            const response = await axiosPrivate.post("coupon/check-unique", { "code": value });

            if (response.data.success === false) {

                return false;
            }
            return true;
        } catch (error) {

            console.error(error);
        }
    };

    const handleCodeBlur = async (event) => {
        const code = event.target.value;
        setCode(code)
        if (code === ambassadorsDetails.code) {
            setIsCodeUnique(true);
            return;
        }
        const isUnique = await checkCodeUnique(code);
        setIsCodeUnique(isUnique);
    };

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const handleAmbassadorsDetails = () => {
        const res = axiosPrivate.get(`/coupon/details?id=${params.id}`)
        return res;
    };


    const { isLoading, isError, error } = useQuery({
        queryKey: 'ambassadorsDetails',
        queryFn: handleAmbassadorsDetails,
        onSuccess: (data) => {
            setAmbassadorsDetails(data?.data?.data)
        }
    })

    const postData = async (data) => {
        const response = axiosPrivate.put(`/coupon/update?coupon_id=${params.id}`, {
            code: data.code,
            value: data.value,
            ambassador_percentage: data.ambassador_percentage
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/ambassadors'
                }, 3000)
            }
        }
    })

    useEffect(() => {
        Object.keys(ambassadorsDetails).forEach((key) => {
            setValue(key, ambassadorsDetails[key]);
        });
    }, [ambassadorsDetails, setValue])

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title="Update Coupon"
            />
            {
                isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div> : isError ? <div>{error}</div> : <div></div>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="code1"
                                    label="Code"
                                    variant="outlined"
                                    placeholder="Enter Code"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    {...register("code")}
                                    onBlur={handleCodeBlur} // <-- Add onBlur event handler
                                    error={!isCodeUnique} // <-- Set error state based on isCodeUnique
                                    helperText={code && (isCodeUnique === false ? <p>Code is NOT available</p> : <p className='text-success'>Code is available </p>)}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="value1"
                                    label="Discount %"
                                    variant="outlined"
                                    placeholder="Enter Discount %"
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("value")}
                                    error={Boolean(errors?.value)}
                                    helperText={errors.value ? errors.value?.message : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="ambassador_percentage3"
                                    label="Kickback %"
                                    variant="outlined"
                                    placeholder="Enter Kickback %"
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("ambassador_percentage")}
                                    error={Boolean(errors?.ambassador_percentage)}
                                    helperText={errors.ambassador_percentage ? errors.ambassador_percentage?.message : ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                    disabled={!isCodeUnique} // <-- Disable button if code is not unique
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >

        </>
    )
}
