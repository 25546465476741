import update from 'immutability-helper'
import { memo, useCallback, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Card } from './Card.js'
import { ItemTypes } from './ItemTypes.js'

export const Container = memo(function Container({ top, isTop }) {

    useEffect(() => {
        setCards(top)
    }, [top])

    const [cards, setCards] = useState([])

    const findCard = useCallback(
        (id) => {
            const card = cards.filter((c) => `${c.id}` === id)[0]
            return {
                card,
                index: cards.indexOf(card),
            }
        },
        [cards],
    )
    const moveCard = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id)

            setCards(
                update(cards, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, card],
                    ],
                }),
            )
        },
        [findCard, cards, setCards],
    )
    const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))





    return (
        <div ref={drop} >
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className='text-start table-text-purple px-5'>Display Name(Da)</th>
                        <th scope="col" className='text-start table-text-purple'>Display Name(En)</th>
                        <th scope="col" className='text-start table-text-purple'>Display Name(Se)</th>
                        <th scope="col" className='text-start table-text-purple'>Tag Name</th>
                        {!isTop && <th scope="col" className='text-start table-text-purple '>New</th>}
                        <th scope="col" className='text-start table-text-purple '>Status</th>
                        <th scope="col" className='text-start text-danger'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {cards?.map((card, index) => (
                        <Card
                            key={card.id}
                            cards={cards}
                            id={`${card.id}`}
                            text={card.display_name}
                            textEn={card.display_name_v2.en}
                            textSe={card.display_name_v2.se}
                            tagName={card.tag_name}
                            status={card.is_active}
                            isTop={isTop}
                            isNew={card.is_new}
                            position={card.id}
                            moveCard={moveCard}
                            findCard={findCard}
                        />
                    ))}
                </tbody>

            </table>

        </div>
    )
})
