import { Box, Button, Chip, FormControl, FormHelperText, Tooltip, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Navbar from '../../../../components/Navbar'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import 'suneditor/dist/css/suneditor.min.css';

import Loaders from '../../../../components/Loaders';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SunEditor from 'suneditor-react';


export default function ProductEdit() {
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const [expanded, setExpanded] = useState('panel1');
  const [initialValues, setInitialValues] = useState({});
  const [priceInfo, setPriceInfo] = useState({});
  const [tags, setTags] = useState([])
  const [ingredient, setIngredient] = useState([]);
  const [skuUnique, setSkuUnique] = useState(true);

  const { register, handleSubmit, watch, formState: { errors }, setValue, getValues, setFocus } = useForm();

  const [text, setText] = useState('<p></p>');
  const [elements, setElements] = useState({
    "energy_kj_100g": 0,
    "energy_kcal_100g": 0,
    "protein_g_100g": 0,
    "carbohydrate_g_100g": 0,
    "of_which_sugars_g": 0,
    "of_which_starch_g": 0,
    "sodium_g": 0,
    "fat_g": 0,
    "of_which_saturates_g": 0,
    "fibre_aoac_g": 0,
    "salt_g_100g": 0,
  })
  const [isEditorValueSet, setIsEditorValueSet] = useState(false);

  // watch form element in react form hook
  // const energy_kj_100g_watch = watch("energy_kj_100g")
  const didMount = useRef(false);

  const convertDecimalSeparator = (inputObject) => {
    const convertedObject = {};

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        let value = inputObject[key]?.toString(); // Convert value to string
        // Replace '.' with ',' in string values
        if (value == null) {
          value = 0;
        } else {
          value = value?.toString(); // Convert value to string
          // Replace '.' with ',' in string values
          value = value?.replace(/\./g, ',');
        }
        convertedObject[key] = value;
      }
    }

    return convertedObject;
  }
  const handleBodyHtmlUpdate = () => {
    let tempBodyHtml = `${text}`
    const convertedElements = convertDecimalSeparator(elements);
    let html = `<table><tr><td><div><strong>Energi</strong></div><td><div>${convertedElements.energy_kj_100g} kj / ${convertedElements.energy_kcal_100g} kcal</div><tr><td><div><strong>Fedt</strong></div><td><div>${convertedElements.fat_g} g</div><tr><td><div>- Heraf mættet</div><td><div>${convertedElements.of_which_saturates_g} g</div><tr><td><div><strong>Kulhydrat</strong></div><td><div>${convertedElements.carbohydrate_g_100g} g</div><tr><td><div>- Heraf sukkerarter</div><td><div>${convertedElements.of_which_sugars_g} g</div><tr><td><div><strong>Protein</strong></div><td><div>${convertedElements.protein_g_100g} g</div><tr><td><div><strong>Salt</strong></div><td><div>${convertedElements.salt_g_100g} g</div></table>`
    let findHtml = tempBodyHtml.replace(/<table>(.*?)<\/table>/s, html)
    setText(findHtml)

  }
  useEffect(() => {
    if (didMount.current) {
      handleBodyHtmlUpdate()
    }
    else {
      didMount.current = true;
    }
  }, [elements])



  const [base64, setBase64] = useState('');

  // const validationSchema = Yup.object().shape({
  //   sku: Yup.string()
  //     .required('Sku is required')
  //     .test('unique', 'Sku must be unique', async function (value) {
  //       const existingSku = await validateUnique(value); // Replace this with your own function that checks if the SKU exists in your database
  //       return !existingSku;
  //     }),
  // });


  const validateUnique = async (value) => {
    const response = await axiosPrivate.post("candy/check-sku", { "sku": value });
    if (!response.data.success) {
      setSkuUnique(false);
      return false;
    }
  }

  const handleProductType = () => {
    const res = axiosPrivate.get(`product-type/all`)
    return res;
  };

  const { data: allProductType = [] } = useQuery({
    queryKey: ['get-ProductType'],
    queryFn: handleProductType,
    cacheTime: 5000,
  })

  const handleImageInputChange = (event) => {
    const imageFile = event?.target?.files[0];

    if (imageFile.size > 1000000) {
      alert("File size should be less than 1MB");
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      setBase64(reader?.result);
      const base64Value = reader?.result?.replace(/^data:image\/[a-z]+;base64,/, '');
      setValue("attachment", base64Value);
      setValue("filename", imageFile?.name);
    };
  };

  function handleDragEnd(result) {
    if (!result?.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const newIngredients = Array.from(ingredient);
    const [removed] = newIngredients.splice(sourceIndex, 1);
    newIngredients.splice(destinationIndex, 0, removed);
    setIngredient(newIngredients);
    setValue("ingredients", newIngredients)
  }


  const handleTextChange = (data) => {
    setText(data);
  }

  const handleElementChange = (e, key) => {
    setValue(key, e.target.value)
    setElements(prev => {
      return {
        ...prev,
        [key]: e.target.value
      }
    })
  }

  const axiosPrivate = useAxiosPrivate();
  const params = useParams();
  const postData = async (data) => {
    const productData = {
      "name": data?.name,
      "subtitle": data?.subtitle,
      // "body_html": data?.body_html === null ? initialValues?.body_html : data?.body_html,
      "sku": data?.sku,
      "short_code": data?.short_code,
      "vendor": data?.brand,
      "alley_side": data?.alley_side,
      "brand": data?.brand,
      "shopify_id": data?.shopify_id,
      "image": data?.attachment,
      "filename": data?.filename,
      "tags": data?.tags ? (typeof data?.tags === "string" ? data?.tags : data?.tags?.join(",")) : "",
      "product_type": data?.product_type,
      "product_supplier": data?.product_supplier,
      "product_variant": data?.product_variant,
      "ingredients": data?.ingredients ? (typeof data?.ingredients === "string" ? data?.ingredients : data?.ingredients?.join(",")) : "",
      "energy_kj_100g": data?.energy_kj_100g,
      "energy_kcal_100g": data?.energy_kcal_100g,
      "protein_g_100g": data?.protein_g_100g,
      "carbohydrate_g_100g": data?.carbohydrate_g_100g,
      "of_which_sugars_g": data?.of_which_sugars_g,
      "of_which_starch_g": data?.of_which_starch_g,
      "sodium_g": data?.sodium_g,
      "fat_g": data?.fat_g,
      "of_which_saturates_g": data?.of_which_saturates_g,
      "fibre_aoac_g": data?.fibre_aoac_g,
      "salt_g_100g": data?.salt_g_100g,
      "product_description_hot_deals": {
        "da": data?.product_description_da,
        "en": data?.product_description_en
      },
      "pricing_info": {
        "country_code": data?.country_code,
        "currency_code": data?.currency_code,
        "number_of_items": data?.number_of_items,
        "weight": data?.weight,
        "selling_price": data?.selling_price,
        "product_status": data?.product_status,
        "cost": data?.cost,
        "product_sku_weight": data?.product_sku_weight,
        "product_sku_weight_level": data?.product_sku_weight_level,
        "product_sku_ideal_weight_level": data?.product_sku_ideal_weight_level,
      }
    }
    productData.body_html = text || ""

    const response = axiosPrivate.put(`candy/update-candy?candy_id=${params.id}&currency_code=DKK`, productData);
    return response;
  }
  const { mutate } = useMutation(postData, {
    onSuccess: (successData) => {
      if (successData?.data?.success) {
        setTimeout(() => {
          window.location.href = '/admin/dashboard/product'
        }, 1000)
      }
      if (successData?.data?.success == false) {
        alert("Server Error")
      }
    },
    onError: (error) => {
      if (error) {
        alert("Something went wrong")
      }
    }

  })



  const onSubmit = data => {
    mutate(data);
  };


  const handleCandyInfo = () => {
    const res = axiosPrivate.get(`candy?candy_id=${params.id}`)
    return res;
  };
  const handleAllergic = () => {
    const res = axiosPrivate.get(`product-adjustment/all`)
    return res;
  };

  const { data: allAllergic = [] } = useQuery({
    queryKey: ['get-allergic'],
    queryFn: handleAllergic,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0
  })


  const handleAddTags = (value, e) => {
    e.preventDefault()
    if (value.trim() !== '') {
      setTags([...tags, value])
      setValue("tags", [...tags, value])
      setValue("tagsFiled", '')
      setTimeout(() => {
        setFocus("tagsFiled")
      }, 300);
    }
  }

  const handleAddIngredient = (value, e) => {
    e.preventDefault()
    if (value.trim() !== '') {
      setIngredient([...ingredient, value])
      setValue("ingredients", [...ingredient, value])
      setValue("ingredientsFiled", '')
      setTimeout(() => {
        setFocus("ingredientsFiled")
      }, 300);
    }
    // tags.push(value)
  }

  const handleDeleteIngredient = (index) => {

    const newIngredient = ingredient.filter((ingre, i) => index !== i)
    setIngredient(newIngredient)
    setValue("ingredients", newIngredient)
  }

  const handleDeleteTags = (index) => {
    const newTags = tags.filter((tag, i) => index !== i)
    setTags(newTags)
    setValue("tags", newTags)
  }
  const { isLoading, isError, error, isFetched, data: apiResData } = useQuery({
    queryKey: ['get-candy-info'],
    queryFn: handleCandyInfo,
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
    onSuccess: (data) => {
      setInitialValues(data?.data?.data);
      setPriceInfo(data?.data?.data?.pricing_info);
    }
  })

  useEffect(() => {
    if (isFetched) {
      setText(apiResData?.data?.data?.body_html || '<p></p>');
      setTimeout(() => {
        setIsEditorValueSet(true)
      }, 50);
    }
  }, [isFetched])


  useEffect(() => {
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key]);
    });

    setValue("product_description_en", initialValues?.product_description?.en)
    setValue("product_description_da", initialValues?.product_description?.da)

    setElements({

      "energy_kj_100g": initialValues?.energy_kj_100g,
      "energy_kcal_100g": initialValues?.energy_kcal_100g,
      "protein_g_100g": initialValues?.protein_g_100g,
      "carbohydrate_g_100g": initialValues?.carbohydrate_g_100g,
      "of_which_sugars_g": initialValues?.of_which_sugars_g,
      "of_which_starch_g": initialValues?.of_which_starch_g,
      "sodium_g": initialValues?.sodium_g,
      "fat_g": initialValues?.fat_g,
      "of_which_saturates_g": initialValues?.of_which_saturates_g,
      "fibre_aoac_g": initialValues?.fibre_aoac_g,
      "salt_g_100g": initialValues?.salt_g_100g,
    })
    initialValues?.ingredients?.split(',') && setIngredient(initialValues?.ingredients?.split(','))
    initialValues?.tags?.split(',') && setTags(initialValues?.tags?.split(','))
    // const defaultValue = getValues("body_html");
    // setText(defaultValue);
  }, [initialValues, setValue]);

  useEffect(() => {
    Object.keys(priceInfo).forEach((key) => {
      setValue(key, priceInfo[key]);
    });
  }, [priceInfo, setValue]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  return (
    <>
      <Navbar
        title="Update Candy"
      />
      {
        isLoading ? <div>

          <div className="d-flex justify-content-center align-items-center">
            <Loaders />
          </div>
        </div> : isError ? <div>{error}</div> : <div></div>
      }
      <div className='container my-5'>
        <Grid container spacing={2}>
          <Grid item xs={2} >

            <div className='m-3'>
              {
                base64 ? <img src={base64} alt="candy" className='img-fluid' /> : <img src={initialValues?.image} alt="" className="img-fluid" />
              }

              <input
                type="file"
                accept="image/*"
                className='form-control'
                onChange={handleImageInputChange} />
            </div>

          </Grid>
          {/* <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light" /> */}
          <Grid item xs={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Candy Information</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="candy-name"
                        label="Candy Name"
                        variant="outlined"
                        placeholder="Enter Candy Name"
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        {...register("name")}
                        error={!!errors?.name}
                        helperText={errors.title?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="subtitle"
                        label="Subtitle"
                        variant="outlined"
                        placeholder="Enter Subtitle"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        margin="normal"
                        {...register("subtitle")}
                        error={Boolean(errors?.subtitle)}
                        helperText={errors.subtitle ? errors.subtitle?.message : ""}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="Short-Code"
                        label="Candy Short Code"
                        variant="outlined"
                        placeholder="Enter Short Code"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        margin="normal"
                        {...register("short_code")}
                        error={Boolean(errors?.short_code)}
                        helperText={errors.short_code ? errors.short_code?.message : ""}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="vendor"
                        label="Vendor"
                        variant="outlined"
                        placeholder="Enter vendor"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        fullWidth={true}
                        {...register("brand")}
                        error={Boolean(errors?.vendor)}
                        helperText={errors.vendor ? errors.vendor?.message : ""}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">Product Supplier</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="product_supplier"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("product_supplier")}
                          value={watch("product_supplier") || []}
                          label="Product Supplier"
                          onChange={(event) => {
                            setValue("product_supplier", event.target.value)
                          }}
                        >
                          <MenuItem value="Cloetta">Cloetta</MenuItem>
                          <MenuItem value="Sugro">Sugro</MenuItem>
                          <MenuItem value="Dankonfekt">Dankonfekt</MenuItem>
                          <MenuItem value="Scan choko">Scan choko</MenuItem>
                          <MenuItem value="Toms">Toms</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">Alley Side</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="alley_side"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("alley_side")}
                          value={watch("alley_side") || ""}
                          label="Alley Side"
                          onChange={(event) => {
                            setValue("alley_side", event.target.value)
                          }}
                        >
                          <MenuItem value="right">Right</MenuItem>
                          <MenuItem value="left">Left</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>
                    {/* <Grid item xs={6} >
                      <>
                        <Typography textAlign="left" >
                          {errors.alley_side ? (
                            <Typography color="error"> Alley Side</Typography>
                          )
                            : <Typography>Alley Side</Typography>
                          }
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          defaultValue={initialValues.alley_side || "left"}
                          onChange={(event) => {
                            setValue("alley_side", event.target.value);
                            register("alley_side");
                          }}
                          fullWidth={true}
                          error={Boolean(errors?.alley_side)}
                        >
                          <FormControlLabel value="left" control={<Radio />} label="Alley Left" />
                          <FormControlLabel value="right" control={<Radio />} label="Alley Right" />
                        </RadioGroup>
                        <input type="hidden" {...register("alley_side")} value="left" />
                        <FormHelperText error={true} >{errors.alley_side ? errors.alley_side?.message : ""}</FormHelperText>

                      </>
                    </Grid> */}
                    <Grid item xs={6}>
                      <>
                        <FormControl fullWidth={true}>
                          <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="product_type"
                            {...register("product_type")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={watch("product_type") || "Pick and Mix"}
                            label="Product Type"
                            onChange={(event) => {
                              setValue("product_type", event.target.value)
                            }}
                            error={Boolean(errors?.product_type)}
                            helperText={errors.product_type ? errors.product_type?.message : ""}
                          >
                            {
                              allProductType?.data?.data?.map((item, index) => {
                                return <MenuItem key={index} value={item?.product_type_code}>
                                  <Tooltip title={item.description} arrow>
                                    <span>{item.name}</span>
                                  </Tooltip>
                                </MenuItem>
                              })

                            }
                          </Select>
                          <FormHelperText error={true} >{errors.product_type ? errors.product_type?.message : ""}</FormHelperText>
                        </FormControl>
                      </>

                    </Grid>

                    {((getValues('product_type') === 'Gift Items')) && <Grid item xs={6}>
                      <>
                        <FormControl fullWidth={true}>
                          <InputLabel id="demo-simple-select-label">Product variant (Gift)</InputLabel>
                          {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}

                          <Select
                            labelId="demo-simple-select-label"
                            id="product_variant"
                            {...register("product_variant")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={watch("product_variant") || "Pick and Mix"}
                            label="Product variant (Gift)"
                            onChange={(event) => {
                              setValue("product_variant", event.target.value)
                            }}
                            error={Boolean(errors?.product_variant)}
                            helperText={errors.product_variant ? errors.product_variant?.message : ""}
                          >
                            <MenuItem value={1}>Box</MenuItem>
                            <MenuItem value={2}>Bottle</MenuItem>
                          </Select>
                          <FormHelperText error={true} >{errors.product_variant ? errors.product_variant?.message : ""}</FormHelperText>
                        </FormControl>
                      </>
                    </Grid>}
                    {
                      (getValues('product_type') === 'Upsell product' || getValues('product_type') === 'Other products' || getValues('product_type') === 'Gift Items') &&
                      <>
                        {getValues('product_type') === 'Gift Items' ? <> </> : <Grid item xs={6}></Grid>}
                        <Grid item xs={6}>
                          <>
                            <Typography textAlign="left" >
                              {errors.product_description_en ? (
                                <Typography color="error"> Product Description  (En)</Typography>
                              )
                                : <Typography>Product Description (En)</Typography>
                              }
                            </Typography>
                            {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                            <textarea minRows={2} defaultValue={getValues('product_description_en')} onBlur={(e) => setValue('product_description_en', e.target.value)} />
                            <FormHelperText error={true} >{errors.product_description_en ? errors.product_description_en?.message : ""}</FormHelperText>
                          </>
                        </Grid>
                        <Grid item xs={6}>
                          <>
                            <Typography textAlign="left" >
                              {errors.product_description_da ? (
                                <Typography color="error"> Product Description  (Da)</Typography>
                              )
                                : <Typography>Product Description (Da)</Typography>
                              }
                            </Typography>
                            {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                            <textarea minRows={2} defaultValue={getValues('product_description_da')} onBlur={(e) => setValue('product_description_da', e.target.value)} />
                            <FormHelperText error={true} >{errors.product_description_da ? errors.product_description_da?.message : ""}</FormHelperText>
                          </>
                        </Grid>
                      </>
                    }




                  </Grid>

                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography>Ingredient</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <>
                        <Typography textAlign="left" >
                          Product Description
                        </Typography>

                        {
                          (isFetched && isEditorValueSet) && (
                            <SunEditor
                              height='200px'
                              defaultValue={text || ''}
                              onBlur={e => handleTextChange(e.target.innerHTML)}

                            />
                          )
                        }
                      </>
                    </Grid>

                    <Grid item xs={12} spacing={2}>
                      <div className='d-flex'>
                        <Grid item xs={6} spacing={2}>
                          <Grid item xs={11}>
                            <div className='d-flex'>
                              <TextField
                                id="tags"
                                label="Ingredients Name"
                                variant="outlined"
                                placeholder="Ingredients Name"
                                fullWidth={true}
                                onKeyDown={(e) => e.keyCode === 13 && handleAddIngredient(getValues("ingredientsFiled"), e)}
                                {...register("ingredientsFiled")}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <Button size="small" color="secondary" variant="contained" sx={{
                                marginTop: "13px",
                                marginBottom: "8px",
                                marginLeft: "-15px",
                              }} onClick={
                                (e) => {
                                  handleAddIngredient(getValues("ingredientsFiled"), e)
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </Grid>
                          <Grid item xs={11}>
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="droppable" direction="vertical">
                                {(provided) => (
                                  <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {ingredient.map((ingre, index) => (
                                      <Draggable
                                        key={`ingredient-${index}`}
                                        draggableId={`ingredient-${index}`}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <Chip
                                            label={ingre}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onDelete={() => handleDeleteIngredient(index)}
                                            sx={{
                                              m: 1,
                                              backgroundColor: "lightpurple",
                                              display: 'flex',
                                              color:
                                                allAllergic &&
                                                  allAllergic?.data?.data.some(
                                                    (item) => item.name === ingre
                                                  )
                                                  ? "black"
                                                  : "inherit",
                                              fontWeight:
                                                allAllergic &&
                                                  allAllergic?.data?.data.some(
                                                    (item) => item.name === ingre
                                                  )
                                                  ? "bold"
                                                  : "normal",
                                              ...provided.draggableProps.style,
                                            }}
                                          />
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                          </Grid>
                          <Grid item xs={11}>
                            <div className='d-flex'>
                              <TextField
                                id="tags"
                                label="Tags"
                                variant="outlined"
                                placeholder="tags"
                                onKeyDown={(e) => e.keyCode === 13 && handleAddTags(getValues("tagsFiled"), e)}
                                fullWidth={true}
                                {...register("tagsFiled")}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <Button size="small" color="secondary" variant="contained" sx={{
                                marginTop: "13px",
                                marginBottom: "8px",
                                marginLeft: "-15px",
                              }} onClick={(e) => handleAddTags(getValues("tagsFiled"), e)
                              } >
                                Add
                              </Button>
                            </div>
                          </Grid>
                          <Grid item xs={11}>

                            {
                              tags.map((tag, index) => {
                                return <Chip
                                  label={tag}
                                  key={index}
                                  onDelete={() => handleDeleteTags(index)}
                                  sx={{ m: 1, display: 'flex', alignItems: 'center' }}
                                />
                              })
                            }
                          </Grid>
                        </Grid>


                        <Grid item xs={6}>
                          <Grid item xs={12}>

                            <TextField
                              id="energy_kj_100g"
                              label="Energy (kj 100g)"
                              variant="outlined"
                              placeholder="Enter Energy (kj 100g)"
                              fullWidth={true}
                              defaultValue={elements.energy_kj_100g}
                              onBlur={(e) => handleElementChange(e, 'energy_kj_100g')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="energy_kcal_100g"
                              label="Energy (kcal 100g)"
                              variant="outlined"
                              onBlur={e => handleElementChange(e, 'energy_kcal_100g')}
                              defaultValue={elements.energy_kcal_100g}
                              InputLabelProps={{
                                shrink: true,
                              }}

                              placeholder="Enter Energy (kcal 100g)"
                              fullWidth={true}
                              margin="normal"

                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="fat_g"
                              label="Fat (g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.fat_g}
                              placeholder="Enter Fat (g)"
                              onBlur={e => handleElementChange(e, 'fat_g')}
                              fullWidth={true}
                              margin="normal"

                            />

                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="of_which_saturates_g"
                              label="Of which Saturates (g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.of_which_saturates_g}
                              placeholder="Enter Of Which Saturates (g)"
                              fullWidth={true}
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'of_which_saturates_g')}
                            />

                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="carbohydrate_g_100g"
                              label="Carbohydrate (g 100g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.carbohydrate_g_100g}
                              placeholder="Enter Carbohydrate (g 100g)"
                              fullWidth={true}
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'carbohydrate_g_100g')}
                            />

                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="of_which_sugars_g"
                              label="Of which sugars (g)"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.of_which_sugars_g}
                              variant="outlined"
                              placeholder="Enter Of which sugars (g)"
                              fullWidth={true}
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'of_which_sugars_g')}
                            />

                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="protein_g_100g"
                              label="Protein (g 100g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.protein_g_100g}
                              placeholder="Enter Protein (g 100g)"
                              fullWidth={true}
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'protein_g_100g')}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id="salt_g_100g"
                              label="Salt (100g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={elements.salt_g_100g}
                              placeholder="Enter Salt (100g)"
                              fullWidth={true}
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'salt_g_100g')}
                            />

                          </Grid>


                          <Grid item xs={12} >
                            <TextField
                              id="of_which_starch_g"
                              label="Of which starch (g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter Of which starch (g)"
                              fullWidth={true}
                              hidden
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'of_which_starch_g')}
                            />
                          </Grid>


                          <Grid item xs={12}>
                            <TextField
                              id="sodium_g"
                              label="Sodium (g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter sodium (g)"
                              fullWidth={true}
                              hidden
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'sodium_g')}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="fibre_aoac_g"
                              label="Fibre aoac (g)"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter Fibre aoac (g)"
                              fullWidth={true}
                              hidden
                              margin="normal"
                              onBlur={e => handleElementChange(e, 'fibre_aoac_g')}
                            />

                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography>Pricing Details</Typography>
                </AccordionSummary>
                <AccordionDetails>

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="price"
                        label="Candy Price"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Enter Candy Price"
                        type="decimal"
                        fullWidth={true}
                        {...register("selling_price")}
                        error={!!errors.price}
                        helperText={errors.price?.message}
                        margin="normal"
                        {...register("selling_price")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="weight"
                        label="Candy Total Weight"
                        variant="outlined"
                        placeholder="Enter Candy Total Weight"
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!errors.weight}
                        helperText={errors.weight?.message}
                        margin="normal"
                        {...register("weight")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="amount"
                        label="Candy Pieces"
                        variant="outlined"
                        placeholder="Enter Candy Pieces"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        error={!!errors.amount}
                        helperText={errors.amount?.message}
                        margin="normal"
                        {...register("number_of_items")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="sku"
                        label="Sku"
                        variant="outlined"
                        placeholder="Enter sku"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth={true}
                        onChange={(e) => validateUnique(e.target.value)}
                        margin="normal"
                        {...register("sku")}
                        error={!skuUnique}
                        helperText={!skuUnique ? "Sku already exists" : ""}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="product_sku_weight"
                        label="Product SKU weight"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        placeholder="Enter Product SKU weight"
                        fullWidth={true}
                        margin="normal"
                        {...register("product_sku_weight")}
                      />

                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                        id="product_sku_weight_level"
                        label="Product SKU weight level"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Enter Product SKU weight level"
                        fullWidth={true}
                        margin="normal"
                        {...register("product_sku_weight_level")}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="product_sku_ideal_weight_level"
                        label="Product SKU ideal weight level"
                        variant="outlined"
                        placeholder="Enter Product ideal SKU weight level"
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        {...register("product_sku_ideal_weight_level")}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="cost"
                        label="Cost Per Item"
                        variant="outlined"
                        placeholder="Enter Cost Per Item"
                        fullWidth={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="normal"
                        {...register("cost")}
                      />

                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="currency_code"
                          {...register("currency_code")}
                          value={watch("currency_code") || ""}
                          label="Currency"
                          onChange={(event) => {
                            setValue("currency_code", event.target.value)
                          }}
                        >
                          <MenuItem value="DKK">DKK</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>

                    {/* <Grid item xs={6}>
                      <>
                        <Typography textAlign="left" >
                          {

                            errors.currency ? (
                              <Typography color="error"> Currency</Typography>
                            )
                              : <Typography>Currency</Typography>

                          }

                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-labelss"
                          name="currency"
                          {...register("currency")}
                          value={priceInfo.currency ? priceInfo.currency : "DKK"}
                          fullWidth={true}
                          error={Boolean(errors?.currency)}
                          helperText={errors.currency ? errors.currency?.message : ""}
                        >
                          <FormControlLabel value="DKK" control={<Radio />} label="DKK" />
                          <FormControlLabel value="USD" control={<Radio />} label="USD" />
                        </RadioGroup>
                        <FormHelperText error={Boolean(errors?.currency)}>{errors.currency ? errors.currency?.message : ""}</FormHelperText>
                      </>
                    </Grid> */}

                    <Grid item xs={6}>
                      <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">Product Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="product_status"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("product_status")}
                          value={watch("product_status") || ""}
                          label="Product Status"
                          onChange={(event) => {
                            setValue("product_status", event.target.value)
                          }}
                        >
                          <MenuItem value={0}>Draft</MenuItem>
                          <MenuItem value={1}>Active</MenuItem>
                          <MenuItem value={2}>Archived</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>
                    {/* <Grid item xs={6}>


                      <>
                        <Typography textAlign="left" >

                          {

                            errors.status ? (
                              <Typography color="error"> Product Status</Typography>
                            )
                              : <Typography>Product Status</Typography>

                          }

                        </Typography>
          
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="status"
                          {...register("status")}
                          value={priceInfo.product_status === 1 ? "active" : priceInfo.product_status === 0 ? "draft" : "archived"}
                          fullWidth={true}
                          error={Boolean(errors?.status)}
                          helperText={errors.status ? errors.status?.message : ""}
                        >
                          <FormControlLabel value="active" control={<Radio />} label="Active" />
                          <FormControlLabel value="draft" control={<Radio />} label="Draft" />
                          <FormControlLabel value="archived" control={<Radio />} label="Archived" />
          
                        </RadioGroup>
                        <FormHelperText error={Boolean(errors?.currency)}>{errors.currency ? errors.currency?.message : ""}</FormHelperText>
                      </>
                    </Grid> */}

                  </Grid>
                </AccordionDetails>
              </Accordion>
              <button type="submit" className="btn btn-primary my-2 float-end">Submit</button>
            </form>
          </Grid>
        </Grid>
      </div >
    </>

  )
}
