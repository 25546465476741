import React, { useMemo, useState, useCallback, useEffect } from 'react';
import Navbar from '../../../components/Navbar';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import Loaders from '../../../components/Loaders';
import { Switch, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Swal from 'sweetalert2';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

export default function GiftCardImage() {
    const axiosPrivate = useAxiosPrivate();
    const [selectedLanguage, setSelectedLanguage] = useState('da'); // Default language
    const [data, setData] = useState([]);
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger me-3',
        },
        buttonsStyling: false,
    });

    const handleGiftCardImage = async () => {
        const response = await axiosPrivate.get(`gift-image/all?language_code=${selectedLanguage}`);
        return response.data.data; // Adjust based on your API response structure
    };

    const { data: allGiftCards = [], isLoading, refetch } = useQuery({
        queryKey: ['get-gift-cards', selectedLanguage],
        queryFn: handleGiftCardImage,
        cacheTime: 5000,
    });

    useEffect(() => {
        if (allGiftCards.length > 0) {
            setData(allGiftCards);
        }
    }, [allGiftCards]);

    const handleGiftCardImageStatus = useCallback(async (id, value) => {
        await axiosPrivate.put(`gift-image/is_active/${id}`, { is_active: value });
        refetch();
    }, [axiosPrivate, refetch]);

    const handleMessageCardDelete = useCallback(async (id) => {
        const result = await swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true,
        });

        if (result.isConfirmed) {
            try {
                const res = await axiosPrivate.delete(`gift-image/delete/${id}`);
                if (res.status === 200) {
                    swalWithBootstrapButtons.fire('Deleted!', 'Gift card has been deleted.', 'success');
                    refetch();
                }
            } catch (error) {
                console.error('Error deleting the card:', error);
                swalWithBootstrapButtons.fire('Error!', 'There was a problem deleting the card.', 'error');
            }
        }
    }, [axiosPrivate, refetch, swalWithBootstrapButtons]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => <img src={row.image} alt="Images" height="50px" />,
            header: "Image",
        },
        {
            accessorFn: (row) => row.title,
            header: "Name",
        },
        {
            accessorFn: (row) => row.price,
            header: "Price",
        },

        {
            accessorFn: (row) => row.language_code,
            header: "Language code",
        },
        {
            accessorFn: (row) => row.count,
            header: "Total Uses",
        },
        {
            accessorFn: (row) => (
                <Switch
                    checked={row.is_active}
                    onChange={(e) => handleGiftCardImageStatus(row.id, e.target.checked)}
                />
            ),
            header: "Status",
        },
        {
            accessorFn: (row) => (
                <>
                    <button
                        onClick={() => handleMessageCardDelete(row.id)}
                        className="btn btn-danger mx-2"
                    >
                        <i className="fa-solid fa-trash"></i>
                    </button>

                    {/* <Link to={`/admin/dashboard/gift-cards/edit/${row.id}`} className='btn btn-success '><i className="fa-regular fa-pen-to-square"></i></Link> */}
                </>

            ),
            id: "action",
            header: "Action",
        },
    ], [handleGiftCardImageStatus, handleMessageCardDelete]);

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowOrdering: true,
        enableSorting: false,
    });

    return (
        <>
            <Navbar
                title="Gift Card"
                btnTitle="Add New Gift Card"
                btnLink="/admin/dashboard/gift-cards/create"
            />
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
            )}
            {
                !isLoading && (

                    <div className="container my-5">
                        <div className="d-flex align-items-center mb-4">
                            {/* Dropdown placed on the left */}
                            <FormControl sx={{ minWidth: 200, marginRight: 2 }}>
                                <InputLabel id="language-select-label">Language</InputLabel>
                                <Select
                                    labelId="language-select-label"
                                    value={selectedLanguage}
                                    onChange={(e) => setSelectedLanguage(e.target.value)}
                                    label="Language"
                                >
                                    <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="da">Danish</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {data.length > 0 && <MaterialReactTable table={table} />}
                    </div>
                )
            }

        </>
    );
}
