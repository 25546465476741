import React from 'react'
import Navbar from '../../../../components/Navbar'
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useState } from 'react';
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';


export default function CreateCategoryMenu() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch, setError } = useForm();
    const [istagNameUnique, setistagNameUnique] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const checkTagNameUnique = async (value) => {
        try {
            const response = await axiosPrivate.post("menu-item/check-uniqueness", { "tag_name": value });

            if (response.data.success === false) {

                return false;
            }
            return true;
        } catch (error) {

            console.error(error);
        }
    };
    ;


    const handleTagNameUniqueBlur = async (event) => {
        const tag = event.target.value;
        const isUnique = await checkTagNameUnique(tag);
        setistagNameUnique(isUnique);
    };


    const keyValue = watch("display_name")
    const handleTranslationWithAI = async () => {
        setIsLoading(true);
        const response = await axiosPrivate.post(`translation/generate-translation-danish`, { key: keyValue, da: watch("display_name") });

        if (response?.data?.success) {
            setValue("display_name_en", response.data.data.en);
            setValue("display_name_se", response.data.data.se);
            setIsLoading(false);
        }
    };
    const postData = async (data) => {
        const response = axiosPrivate.post('menu-item/create', {
            display_name: data.display_name,
            display_name_en: data.display_name_en,
            display_name_se: data.display_name_se,
            group: data.group,
            is_in_top_list: data.is_in_top_list,
            is_in_vertical_list: data.is_in_vertical_list,
            tag_name: data.tag_name,
            top_list_position: 0,
            vertical_list_position: 0,
            is_active: true
        });
        return response;
    }

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/category-menu'
                }, 3000)
            }
        }
    })

    const onSubmit = (data) => {
        mutate(data);
    }
    return (
        <>
            <Navbar
                title={'Create Category Menu'}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="display_name"
                                    label="Display Name"
                                    variant="outlined"
                                    placeholder="Enter Display Name"
                                    fullWidth={true}
                                    required={true}
                                    margin="normal"
                                    {...register("display_name")}
                                    error={!!errors?.display_name}
                                    helperText={errors.display_name?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="display_name_en"
                                    label="Display Name (En)"
                                    variant="outlined"
                                    placeholder="Enter Display Name (En)"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    {...register("display_name_en")}
                                    error={!!errors?.display_name_en}
                                    helperText={errors.display_name?.display_name_en}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="display_name_se"
                                    label="Display Name (SE)"
                                    variant="outlined"
                                    placeholder="Enter Display Name (SE)"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth={true}
                                    margin="normal"
                                    {...register("display_name_se")}

                                    error={!!errors?.display_name_se}
                                    helperText={errors.display_name?.display_name_se}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="tag_name"
                                    label="Tag Name"
                                    variant="outlined"
                                    required={true}
                                    placeholder="Enter Tag Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin="normal"
                                    fullWidth={true}
                                    {...register("tag_name")}
                                    onBlur={handleTagNameUniqueBlur} // <-- Add onBlur event handler
                                    error={!istagNameUnique} // <-- Set error state based on istagNameUnique
                                    helperText={!istagNameUnique ? "This tag_name is already taken" : ""}
                                />

                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">In Top Category Menu</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_in_top_list"
                                        required={true}
                                        {...register("is_in_top_list")}
                                        label="In Top Category Menu"
                                        onChange={(event) => {
                                            setValue("is_in_top_list", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">In Vertical Category Menu</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_in_vertical_list"
                                        required={true}
                                        {...register("is_in_vertical_list")}
                                        label="In Top Category Menu"
                                        onChange={(event) => {
                                            setValue("is_in_vertical_list", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <InputLabel id="demo-simple-select-label">Category Group</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="group"
                                        {...register("group")}
                                        required={true}
                                        label="Category Group"
                                        onChange={(event) => {
                                            setValue("group", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={"inhold"}>InHold</MenuItem>
                                        <MenuItem value={"kategori"}>Kategori</MenuItem>
                                        <MenuItem value={"favoritter"}>Favoritter</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                    disabled={!istagNameUnique} // <-- Disable button if code is not unique
                                >Submit</button>

                                <button
                                    className="btn btn-info text-white ms-3"
                                    type="button"
                                    onClick={async () => {
                                        // Perform validation
                                        const keyError = !watch("display_name") ? "Name is required." : null;


                                        if (keyError) {
                                            setError("display_name", { message: keyError });

                                            return;
                                        }

                                        setIsLoading(true); // Show loading state
                                        try {
                                            await handleTranslationWithAI();
                                        } finally {
                                            setIsLoading(false); // Reset loading state
                                        }
                                    }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress color="success" /> : "Generate Translation With AI"}
                                </button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >

        </>
    )
}
