import { useQueries } from '@tanstack/react-query';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../api/axios';
import './PackingOngoingStatus.css'
import logo from '../../assets/Slikekspressen.png'
import Loaders from '../../components/Loaders';
import { Rating } from '@mui/material';
import { Link } from 'react-router-dom';
import nodata from '../../assets/nodata.svg'

export default function PackingOngoingStatus() {
    const [avgTime, setAvgTime] = useState();
    const [progress, setProgress] = useState(0);

    const [showComponentOne, setShowComponentOne] = useState(true);

    const handleOrderStatistic = () => {
        const res = axios.get(`order/statistic`)
        return res;
    };

    const handleOngoingOrder = () => {
        const res = axios.get(`order/dashboard/ongoing-orders`)
        return res;
    };

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (progress < 100) {
    //             setProgress(progress + 1);
    //         }
    //         else if (progress === 100) {
    //             setShowComponentOne((prev) => !prev);
    //             setProgress(0);
    //         }
    //     }, 600);

    //     return () => clearInterval(intervalId); // cleanup function
    // }, [progress]);


    const handleShift = () => {
        const res = axios.get(`dashboard/shift/all`)
        return res;
    };

    const handleFeedbacks = () => {
        const res = axios.get(`order/dashboard/feedback`)
        return res;
    };
    const [statisticQuery, ongoingQuery, shiftQuery, feedbackQuery] = useQueries({
        queries: [
            {
                queryKey: 'get-Statistic',
                queryFn: handleOrderStatistic,
                refetchInterval: 5000,
                refetchOnWindowFocus: true,
            },
            {
                queryKey: 'get-ongoing',
                queryFn: handleOngoingOrder,
                refetchInterval: 5000,
                refetchOnWindowFocus: true,
            },
            {
                queryKey: 'get-shift',
                queryFn: handleShift,
                refetchInterval: 5000,
                refetchOnWindowFocus: true,
            },
            // {
            //     queryKey: 'get-feedback',
            //     queryFn: handleFeedbacks,
            //     refetchInterval: 65000,
            //     refetchOnWindowFocus: true,
            // }
        ]

    })

    useEffect(() => {
        const timeStr = statisticQuery?.data?.data?.data?.avg_packed_time || "00:00";
        const minutes = timeStr?.slice(0, 2);
        const seconds = timeStr?.slice(3, 6);
        const formattedTime = `${minutes} : ${seconds}`;
        setAvgTime(formattedTime);

    }, [statisticQuery?.data])

    return (
        <div className='dashboard-backgroud-color'>
            {
                statisticQuery?.isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
                    :
                    showComponentOne ?
                        <div className='pt-4 px-4'>
                            <div className='d-lg-flex justify-content-between'>
                                <div className="card card-packing">
                                    <h5 className="card-header card-header-color">Åbne ordrer</h5>
                                    <div className="card-body">
                                        <h1 className='card-count'>{statisticQuery?.data?.data?.data?.open_orders}</h1>
                                    </div>
                                </div>
                                <div className="card card-packing">
                                    <h5 className="card-header card-header-color">Pakket i dag</h5>
                                    <div className="card-body">
                                        <h1 className='card-count'>{statisticQuery?.data?.data?.data?.packed_today}</h1>
                                    </div>
                                </div>
                                <div >
                                    <div className="card card-avg-time">
                                        <div className='d-flex'>
                                            <div className='avg-text'>
                                                <h5>Pakketid:</h5>
                                                <div>
                                                    Sidste 30 dage
                                                </div>
                                            </div>

                                            <div className='avg-time'>
                                                <h1>{avgTime}</h1>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div>
                                    <img className='slik-logo-fornt' src={logo} alt="logo" />
                                </div>


                            </div>
                            <div className='d-lg-flex justify-content-between'>
                                <div>
                                    <div className="card card-status-color">
                                        <h5 className="card-header card-header-color">Igangværende</h5>
                                        <div className="card-body overflow-auto">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='text-start table-header-color'>Navn</th>
                                                        <th scope="col" className='text-start table-header-color'>Ordre</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        ongoingQuery?.data?.data?.data?.length ? <>
                                                            {ongoingQuery?.data?.data?.data?.map((item, index) => {
                                                                return (
                                                                    <tr key={index + 1}>
                                                                        <td className='text-start'>{
                                                                            item?.employee_image ? <img className="image-round me-3" src={item?.employee_image} alt={item?.employee} />
                                                                                : <i className="fa-solid fa-circle-user fa-2xl me-3"></i>
                                                                        }  {item?.employee}</td>
                                                                        <td className='text-start'>{item?.order_details?.order_tracking_id.startsWith('APP') ? item?.order_details?.order_tracking_id : `SHOPIFY-${item?.order_details?.order_tracking_id}`}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </> : <tr>
                                                            <td colSpan="3 table-header-color" > No data found </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    {/* <div>
                                    <div className="progress progress-margin">
                                        <div
                                            className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                                            role="progressbar"
                                            style={{
                                                width: `${progress}%`,
                                            }}
                                        ></div>
                                    </div>
                                </div> */}
                                </div>


                                <div>
                                    <div className="card card-shift">
                                        <h5 className="card-header card-header-color">Vagtplan i dag</h5>
                                        <div className="card-body overflow-auto">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='table-header-color'>Navn</th>
                                                        <th scope="col" className='table-header-color'>Tid</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        shiftQuery?.data?.data?.data?.length ? shiftQuery?.data?.data?.data?.map((item, index) => {
                                                            return (
                                                                <tr>

                                                                    <td>{item?.name}</td>
                                                                    <td>{item?.working_shift?.start_time} - {item?.working_shift?.end_time}</td>
                                                                </tr>
                                                            )
                                                        })
                                                            : <>
                                                                <td colSpan="3" className=' table-header-color' > No data found </td>
                                                            </>
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>

                                    {/* <Link className='feedbacks-button' to='/get-feedbacks'>Get All Feedbacks</Link> */}
                                </div>
                            </div>
                        </div > :
                        <div className='px-4'>

                            <div className='py-3'>
                                <img className='slik-logo' src={logo} alt="logo" />
                            </div>
                            <div className='d-lg-flex justify-content-between'>
                                <div>
                                    <div className="card card-ratings">
                                        <h5 className="card-header card-header-color text-start ps-5">Ordredetaljer </h5>
                                        <div className="card-body">
                                            <div className='my-3'>
                                                <p className='feedbacks-title'>Kundenavn</p>
                                                <p className='customer-Name'>{feedbackQuery?.data?.data?.data[0]?.user?.name}</p>
                                            </div>

                                            <div className='my-3'>
                                                <p className='feedbacks-title'>Pakker</p>
                                                <p className='customer-Name'>{feedbackQuery?.data?.data?.data[0]?.packing_order?.employee_name}</p>
                                            </div>

                                            <div>
                                                <p className='feedbacks-title'>Kundevurdering</p>
                                                <div className='rating-card mt-3'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3' >Ordering experience</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" value={feedbackQuery?.data?.data?.data[0]?.order_experience} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='rating-card my-3'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3'>Freshness of candy</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" value={feedbackQuery?.data?.data?.data[0]?.candy_freshness} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='rating-card'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3'>The shipping company</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" value={feedbackQuery?.data?.data?.data[0]?.shipping_company} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    {/* <div className="progress m-3 progress-margin">
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-warning"
                        role="progressbar"
                        style={{
                            width: `${progress}%`,
                        }}
                    ></div>
                </div> */}
                                </div>


                                <div className="card card-feedbacks">
                                    <h5 className="card-header card-header-color  text-start ps-5">Anmeldelse</h5>
                                    <div className="card-body">
                                        <p className='text-description'>{feedbackQuery?.data?.data?.data[0]?.comment ? feedbackQuery?.data?.data?.data[0]?.comment : <div className='nodata-align'>
                                            <img src={nodata} className='nodata' alt='nodata' />
                                            <p className='nodata-text'>No Data Found</p>
                                        </div>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
            }

        </div >

    );
};