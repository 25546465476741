import Navbar from '../../../../components/Navbar'
import { Grid, TextField, InputLabel, MenuItem, Select, FormControl, Typography } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function UpdateOrderType() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, watch, getValues } = useForm();
    const params = useParams();
    const [orderTypeImageEn, setOrderTypeImageEn] = useState(null);
    const [orderTypeImageDa, setOrderTypeImageDa] = useState(null);
    const [orderTypeImageSe, setOrderTypeImageSe] = useState(null);
    const [basketImage, setBasketImage] = useState(null);
    const [imageEn, setImageEn] = useState(null);
    const [imageDa, setImageDa] = useState(null);
    const [imageSe, setImageSe] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [imageBasket, setImageBasket] = useState(null);
    const postData = async (data) => {
        const makeData = {
            label: {
                en: data.label_en ? data.label_en : getValues('label.en'),
                da: data.label_da ? data.label_da : getValues('label.da'),
                se: data.label_se ? data.label_se : getValues('label.se')

            },
            description: {
                en: data.description_en ? data.description_en : getValues('description.en'),
                da: data.description_da ? data.description_da : getValues('description.da'),
                se: data.description_se ? data.description_se : getValues('description.se')
            },
            order_type: data.order_type,
            is_active: data.is_active,
            images: {
                en: orderTypeImageEn ? orderTypeImageEn : null,
                da: orderTypeImageDa ? orderTypeImageDa : null,
                se: orderTypeImageSe ? orderTypeImageSe : null
            },
            basket_image: basketImage ? basketImage : null
        }
        const response = axiosPrivate.put(`order-type/update/${params.id}`, makeData);
        return response;
    }

    const handleOrderTypes = () => {
        const res = axiosPrivate.get(`order-type/details/${params.id}`)
        return res;
    }

    const { data: orderType } = useQuery({
        queryKey: ['get-orderType'],
        queryFn: handleOrderTypes
    })


    useEffect(() => {
        if (orderType?.data?.data) {
            Object.keys(orderType.data.data).forEach((key) => {
                setValue(key, orderType.data.data[key]);
            });
            setValue('label_en', orderType.data.data.label.en)
            setValue('label_da', orderType.data.data.label.da)
            setValue('label_se', orderType.data.data.label.se)
            setImageEn(orderType.data.data.images.en)
            setImageDa(orderType.data.data.images.da)
            setImageSe(orderType.data.data.images.se)
            setImageBasket(orderType.data.data.basket_image)
        }
    }, [orderType, setValue])

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/order-types'
                }, 3000)
            }
        },
        onError: (error) => {
            setIsLoading(false)
        }
    })

    const handleFile = (event, value) => {
        const imageFile = event?.target?.files[0];

        if (imageFile.size > 1000000) {
            alert("File size should be less than 1MB");
            return;
        }
        const reader = new FileReader();


        reader.readAsDataURL(imageFile);
        reader.onload = () => {
            if (value === "da") {
                setOrderTypeImageDa(reader?.result);
            } else if (value === "en") {
                setOrderTypeImageEn(reader?.result);
            }

            else if (value === "se") {
                setOrderTypeImageSe(reader?.result);
            }
            else if (value === "basket") {
                setBasketImage(reader?.result);
            }
        };
    }


    const onSubmit = (data) => {
        setIsLoading(true)
        mutate(data);
    }




    return (
        <>
            <Navbar
                title="Update a OrderType"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='container my-5'>
                    <div className='card'>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    id="label"
                                    label="Label (En)"
                                    variant="outlined"
                                    placeholder="Enter Label (En)"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("label_en")}
                                    error={!!errors?.label}
                                    helperText={errors.label?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="label"
                                    label="Label (Da)"
                                    variant="outlined"
                                    placeholder="Enter Label (da)"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("label_da")}
                                    error={!!errors?.label}
                                    helperText={errors.label?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    id="label (SE)"
                                    label="Label (Se)"
                                    variant="outlined"
                                    placeholder="Enter Label (Se)"
                                    fullWidth={true}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register("label_se")}
                                    error={!!errors?.label_se}
                                    helperText={errors.label_se?.message}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Order Types</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="type"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("order_type")}
                                        value={watch("order_type") || ""}
                                        label="Order Type"
                                        onChange={(event) => {
                                            setValue("order_type", event.target.value)
                                        }}
                                    >
                                        <MenuItem value="pick_and_mix">Pick and Mix</MenuItem>
                                        <MenuItem value="invited_bag">Invite friend</MenuItem>
                                        <MenuItem value="gift">Gift Items</MenuItem>
                                        <MenuItem value="other_product">Other Products</MenuItem>
                                        <MenuItem value="gift_card">Gift Card</MenuItem>
                                        <MenuItem value="father_day">Father Day</MenuItem>
                                    </Select> </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth={true} sx={{ marginY: '16px' }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="is_active"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...register("is_active")}
                                        value={watch("is_active") || ""}
                                        label="Status"
                                        onChange={(event) => {
                                            setValue("is_active", event.target.value)
                                        }}
                                    >
                                        <MenuItem value={1}> Active</MenuItem>
                                        <MenuItem value={0}> Inactive</MenuItem>
                                    </Select> </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <>
                                    <Typography textAlign="left" >
                                        {errors.order_description_en ? (
                                            <Typography color="error">Description  (En)</Typography>
                                        )
                                            : <Typography>Description (En)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} defaultValue={getValues('description.en')} onChange={(e) => setValue('description_en', e.target.value)} />

                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <>
                                    <Typography textAlign="left" >
                                        {errors.order_description_da ? (
                                            <Typography color="error">Description  (Da)</Typography>
                                        )
                                            : <Typography>Description (Da)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} defaultValue={getValues('description.da')} onChange={(e) => setValue('description_da', e.target.value)} />
                                </>
                            </Grid>

                            <Grid item xs={6}>
                                <>
                                    <Typography textAlign="left" >
                                        {errors.order_description_da ? (
                                            <Typography color="error">Description  (Se)</Typography>
                                        )
                                            : <Typography>Description (Se)</Typography>
                                        }
                                    </Typography>
                                    {/* <FormLabel className='text-start'    fullWidth={true} id="demo-radio-buttons-group-label" >Alley Side</FormLabel> */}
                                    <textarea minRows={2} defaultValue={getValues('description.se')} onChange={(e) => setValue('description_se', e.target.value)} />
                                </>
                            </Grid>


                            <Grid item xs={6}>
                                <img src={orderTypeImageEn ? orderTypeImageEn : imageEn} alt='' height="100px" />
                            </Grid>



                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Order Type Image (En) (110 * 110)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "en")} />
                            </Grid>

                            <Grid item xs={6}>
                                <img src={orderTypeImageDa ? orderTypeImageDa : imageDa} alt='' height="100px" />
                            </Grid>
                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Order Type Image (Da) (110 * 110)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "da")} />
                            </Grid>
                            <Grid item xs={6}>
                                <img src={orderTypeImageDa ? orderTypeImageDa : imageDa} alt='' height="100px" />
                            </Grid>
                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Order Type Image (SE) (110 * 110)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "se")} />
                            </Grid>

                            <Grid item xs={6}>
                                <img src={basketImage ? basketImage : imageBasket} alt='' height="100px" />
                            </Grid>
                            <Grid item xs={6}>
                                <label for="formFile" class="form-label">Upload Basket Image (75 * 75)</label>
                                <input class="form-control" type="file" id="formFile" name="flag" accept=".png,.jpg,.jpeg" onChange={e => handleFile(e, "basket")} />
                            </Grid>


                            <Grid item xs={6}></Grid>


                            <Grid item xs={12}>
                                <button
                                    className='btn btn-success text-white'
                                    type="submit"
                                    disabled={isLoading}
                                >Submit</button>

                            </Grid>
                        </Grid>


                    </div>

                </div>
            </form >
        </>
    )
}
