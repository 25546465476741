import { useQueries } from '@tanstack/react-query';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../api/axios';

import logo from '../../assets/Slikekspressen.png'
import Loaders from '../../components/Loaders';

export default function SlikDashboard() {
    const [avgTime, setAvgTime] = useState();

    const handleOrderStatistic = () => {
        const res = axios.get(`order/statistic`)
        return res;
    };

    const handleOngoingOrder = () => {
        const res = axios.get(`order/dashboard/ongoing-orders`)
        return res;
    };

    const convertPointToComma = (value) => {
        return value?.toString().replace('.', ',')

    }



    const handleRating = () => {
        const res = axios.get(`dashboard/statistic/rating`)
        return res;
    };

    const [statisticQuery, ongoingQuery, RatingQuery] = useQueries({
        queries: [
            {
                queryKey: 'get-Statistic',
                queryFn: handleOrderStatistic,
                refetchInterval: 5000,
                refetchOnWindowFocus: true,
            },
            {
                queryKey: 'get-ongoing',
                queryFn: handleOngoingOrder,
                refetchInterval: 5000,
                refetchOnWindowFocus: true,
            },
            {
                queryKey: 'get-rating',
                queryFn: handleRating,
                refetchInterval: 50000,
                refetchOnWindowFocus: true,
            }
        ]

    })

    useEffect(() => {
        const timeStr = statisticQuery?.data?.data?.data?.avg_packed_time || "00:00";
        const minutes = timeStr?.slice(0, 2);
        const seconds = timeStr?.slice(3, 6);
        const formattedTime = `${minutes} : ${seconds}`;
        setAvgTime(formattedTime);

    }, [statisticQuery?.data])

    return (
        <div className='dashboard-backgroud-color'>
            {
                statisticQuery?.isLoading ? <div className="d-flex justify-content-center align-items-center">
                    <Loaders />
                </div>
                    :
                    <div className='pt-4 px-4'>
                        <div className='d-lg-flex justify-content-between'>
                            <div className="card card-packing">
                                <h5 className="card-header card-header-color-rating">Åbne ordrer</h5>
                                <div className="card-body">
                                    <h1 className='card-count'>{statisticQuery?.data?.data?.data?.open_orders}</h1>
                                </div>
                            </div>
                            <div className="card card-packing">
                                <h5 className="card-header card-header-color-rating">Pakket i dag</h5>
                                <div className="card-body">
                                    <h1 className='card-count'>{statisticQuery?.data?.data?.data?.packed_today}</h1>
                                </div>
                            </div>
                            <div >
                                <div className="card card-avg-time">
                                    <div className='d-flex'>
                                        <div className='avg-text'>
                                            <h5>Pakketid:</h5>
                                            <div>
                                                Sidste 30 dage
                                            </div>
                                        </div>

                                        <div className='avg-time'>
                                            <h1>{avgTime}</h1>

                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div>
                                <img className='slik-logo-fornt' src={logo} alt="logo" />
                            </div>


                        </div>
                        <div className='d-lg-flex justify-content-between'>
                            <div>
                                <div className="card card-status-color">
                                    <h5 className="card-header card-header-color-rating">Igangværende</h5>
                                    <div className="card-body overflow-auto">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='text-start table-header-color'>Navn</th>
                                                    <th scope="col" className='text-start table-header-color'>Ordre</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ongoingQuery?.data?.data?.data?.length ? <>
                                                        {ongoingQuery?.data?.data?.data?.map((item, index) => {
                                                            return (
                                                                <tr key={index + 1}>
                                                                    <td className='text-start'>{
                                                                        item?.employee_image ? <img className="image-round me-3" src={item?.employee_image} alt={item?.employee} />
                                                                            : <i className="fa-solid fa-circle-user fa-2xl me-3"></i>
                                                                    }  {item?.employee}</td>
                                                                    <td className='text-start'>{item?.order_details?.order_tracking_id.startsWith('APP') ? item?.order_details?.order_tracking_id : `SHOPIFY-${item?.order_details?.order_tracking_id}`}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </> : <tr>
                                                        <td colSpan="3 table-header-color" > No data found </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>


                            <div >
                                <div class="row card-order-rating">
                                    <div className="col-6">
                                        <div className="card card-packing-rating">
                                            <h6 className="card-header card-header-color-rating">Satisfaction</h6>
                                            <div className="card-body card-body-wrapper">
                                                <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.order_experience_percentage < 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.order_experience_percentage < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.order_experience_percentage)}%</div>
                                                <h1 className='card-count-rating '>{convertPointToComma(RatingQuery?.data?.data?.data?.last_month_order_experience_rating)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card card-packing-rating">
                                            <h6 className="card-header card-header-color-rating">Freshness</h6>
                                            <div className="card-body card-body-wrapper">
                                                <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.candy_freshness_percentage < 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.candy_freshness_percentage < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.candy_freshness_percentage)}%</div>
                                                <h1 className='card-count-rating'>{convertPointToComma(RatingQuery?.data?.data?.data?.last_month_candy_freshness_rating)}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card card-packing-rating">
                                            <h5 className="card-header card-header-color-rating">Order Cost B2C</h5>
                                            <div className="card-body card-body-wrapper">
                                                <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.b2c_differance > 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.b2c_differance < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.b2c_differance)}%</div>
                                                <h1 className='card-count-rating'>{convertPointToComma(RatingQuery?.data?.data?.data?.cost_per_package_30)}</h1>
                                                <h6 className='kr'>(kr)</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card card-packing-rating">
                                            <h5 className="card-header card-header-color-rating">Unit Cost Prepacked</h5>
                                            <div className="card-body card-body-wrapper">
                                                <div className={`rating-percentage ${RatingQuery?.data?.data?.data?.prepacked_difference > 0 ? 'text-danger' : 'text-success'}`}><i class={`fa-solid fa-chevron-${RatingQuery?.data?.data?.data?.prepacked_difference < 0 ? 'down' : 'up'}`}></i>{convertPointToComma(RatingQuery?.data?.data?.data?.prepacked_difference)}%</div>
                                                <h1 className='card-count-rating'>{convertPointToComma(RatingQuery?.data?.data?.data?.cost_per_package_prepacked_30_days)}</h1>
                                                <h6 className='kr'>(kr)</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div >
            }

        </div >

    );
}
