import { Rating } from '@mui/material'
import React, { useState } from 'react'
import logo from '../../assets/Slikekspressen.png'
import axios from '../../api/axios';
import { useQuery } from '@tanstack/react-query';
import Loaders from '../../components/Loaders';
import nodata from '../../assets/nodata.svg'


export default function AllFeedbacks() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === feedbackQuery?.data?.data?.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? feedbackQuery?.data?.data?.length - 1 : prevIndex - 1
        );
    };
    const handleFeedbacks = () => {
        const res = axios.get(`order/dashboard/feedback`)
        return res;
    };
    const { data: feedbackQuery = [], isLoading } = useQuery({
        queryKey: ['get-feedbacks'],
        queryFn: handleFeedbacks,
        refetchOnWindowFocus: false,

    })
    return (
        <div className='dashboard-backgroud-color'>
            <>
                {
                    isLoading && <div className="d-flex justify-content-center align-items-center">
                        <Loaders />
                    </div>
                }
            </>
            {

                feedbackQuery?.data?.data?.map((feedback, index) => (
                    <div>
                        <div className='px-4' key={index} style={{ display: index === currentIndex ? "block" : "none" }}>

                            <div className='py-3'>
                                <img className='slik-logo' src={logo} alt="logo" />

                            </div>
                            <div className='d-lg-flex justify-content-between'>
                                <div>
                                    <div className="card card-ratings">
                                        <h5 className="card-header card-header-color text-start ps-5">Ordredetaljer </h5>
                                        <div className="card-body">
                                            <div className='my-3'>
                                                <p className='feedbacks-title'>Kundenavn</p>
                                                <p className='customer-Name'>{feedback?.user?.name}</p>
                                            </div>

                                            <div className='my-3'>
                                                <p className='feedbacks-title'>Pakker</p>
                                                <p className='customer-Name'>{feedback?.packing_order?.employee_name}</p>
                                            </div>

                                            <div>
                                                <p className='feedbacks-title'>Kundevurdering</p>
                                                <div className='rating-card mt-3'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3' >Ordering experience</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" sx={{
                                                                    scaleX: { '& .MuiRating-iconFilled': { transform: 'scaleX(-1)' } },


                                                                }} value={feedback?.order_experience} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='rating-card my-3'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3'>Freshness of candy</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" value={feedback?.candy_freshness} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='rating-card'>
                                                    <div class="container text-center">
                                                        <div class="row">
                                                            <div class="col text-start mt-1">
                                                                <p className='ps-3'>The shipping company</p>
                                                            </div>
                                                            <div class="col text-end mt-2">
                                                                <Rating name="read-only" value={feedback?.shipping_company} readOnly />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="card card-feedbacks-packing">
                                    <h5 className="card-header card-header-color  text-start ps-5">Anmeldelse</h5>
                                    <div className="card-body">
                                        <p className=' text-description'>{feedback?.comment ? feedback?.comment : <div className='nodata-align'>
                                            <img src={nodata} className='nodata' alt='nodata' />
                                            <p className='nodata-text'>No Data Found</p>
                                        </div>}</p>
                                    </div>
                                </div>

                            </div>
                            <p className='indexing-number'>{index + 1} / {feedbackQuery?.data?.data?.length}</p>
                        </div>
                    </div>
                ))

            }

            <div className='float-end my-3'>
                <button className='arrow-button mx-3' onClick={handlePrevClick}><i class="fa-solid fa-arrow-left"></i></button>
                <button className='arrow-button me-4' onClick={handleNextClick}><i class="fa-solid fa-arrow-right"></i></button>
            </div>
        </div>
    )
}
