import Navbar from '../../../../components/Navbar'
import { CircularProgress, Grid } from '@mui/material'
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

export default function CreateTranslation() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const keyValue = watch("key"); // Watch the key field for changes

    // Check if the key is unique (POST method)
    useEffect(() => {
        if (!keyValue) return; // Skip if key is empty

        const checkUniqueKey = async () => {
            try {
                const response = await axiosPrivate.post(`translation/check-unique`, { key: keyValue });
                if (response?.data?.success) {
                    setError("key", {
                        type: "manual",
                        message: "Key already exists. Please use a unique key."
                    });
                } else {
                    clearErrors("key");
                }
            } catch (error) {
                console.error("Error checking key uniqueness", error);
            }
        };

        const debounce = setTimeout(checkUniqueKey, 500); // Add a small delay for debouncing
        return () => clearTimeout(debounce);
    }, [keyValue, setError, clearErrors, axiosPrivate]);

    // Post data function
    const postData = async (data) => {
        const makeData = {
            key: data.key,
            en: data.en,
            da: data.da,
            se: data.se
        };

        const response = axiosPrivate.post(`translation/create`, makeData);
        return response;
    };

    const handleTranslationWithAI = async () => {
        setIsLoading(true);
        const response = await axiosPrivate.post(`translation/generate-translation`, { key: keyValue, en: watch("en") });

        if (response?.data?.success) {
            setValue("da", response.data.data.da);
            setValue("se", response.data.data.se);
            setIsLoading(false);
        }
    };

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/translation';
                }, 3000);
            }
        },
        onError: (error) => {
            setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        setIsLoading(true);
        mutate(data);
    };

    return (
        <>
            <Navbar title="Create a Translation" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container my-5">
                    <div className="card ps-5">
                        <Grid container spacing={2}>
                            {/* Column 1: Key */}
                            <Grid item xs={6}>
                                <div className="form-group">
                                    <label htmlFor="key">Key</label>
                                    <textarea
                                        id="key"
                                        className="form-control"
                                        placeholder="Enter Key"
                                        required
                                        {...register("key", {
                                            required: "Key is required.",
                                            onChange: () => clearErrors("key"), // Clear error on change
                                        })}
                                        rows="8"
                                    />
                                    {errors.key && (
                                        <p className="text-danger">{errors.key.message}</p>
                                    )}
                                </div>
                            </Grid>

                            {/* Column 2: Translations */}
                            <Grid item xs={6}>
                                {/* English */}
                                <div className="form-group">
                                    <label htmlFor="key_en">English</label>
                                    <textarea
                                        id="key_en"
                                        className="form-control"
                                        placeholder="Enter English"
                                        {...register("en", {
                                            required: "English translation is required.",
                                            onChange: () => clearErrors("en"), // Clear error on change
                                        })}
                                        rows="2"
                                    />
                                    {errors.en && (
                                        <p className="text-danger">{errors.en.message}</p>
                                    )}
                                </div>

                                {/* Danish */}
                                <div className="form-group mt-3">
                                    <label htmlFor="key_da">Danish</label>
                                    <textarea
                                        id="key_da"
                                        className="form-control"
                                        placeholder="Enter Danish"
                                        {...register("da", {
                                            onChange: () => clearErrors("da"), // Clear error on change
                                        })}
                                        rows="2"
                                    />
                                    {errors.da && (
                                        <p className="text-danger">{errors.da.message}</p>
                                    )}
                                </div>

                                {/* Swedish */}
                                <div className="form-group mt-3">
                                    <label htmlFor="key_se">Swedish</label>
                                    <textarea
                                        id="key_se"
                                        className="form-control"
                                        placeholder="Enter Swedish"
                                        {...register("se", {
                                            onChange: () => clearErrors("se"), // Clear error on change
                                        })}
                                        rows="2"
                                    />
                                    {errors.se && (
                                        <p className="text-danger">{errors.se.message}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "40px" }}>
                                {/* Submit Button */}
                                <button
                                    className="btn btn-success text-white"
                                    disabled={isLoading || Object.keys(errors).length > 0}
                                    type="submit"
                                >
                                    Submit
                                </button>

                                {/* Generate Translation Button */}
                                <button
                                    className="btn btn-info text-white ms-3"
                                    type="button"
                                    onClick={async () => {
                                        // Perform validation
                                        const keyError = !watch("key") ? "Key is required." : null;
                                        const enError = !watch("en") ? "English translation is required." : null;

                                        if (keyError || enError) {
                                            setError("key", { message: keyError });
                                            setError("en", { message: enError });
                                            return;
                                        }

                                        setIsLoading(true); // Show loading state
                                        try {
                                            await handleTranslationWithAI();
                                        } finally {
                                            setIsLoading(false); // Reset loading state
                                        }
                                    }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress color="success" /> : "Generate Translation With AI"}
                                </button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>


        </>
    );
}
