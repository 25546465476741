import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// import DashboardLayout from '../layouts/DashboardLayout';
// import Main from '../layouts/Main';
// import AllergicElements from '../pages/Dashoboard/AllergicElements/AllergicElements';
import Home from '../pages/Dashoboard/Home/Home';
import Product from '../pages/Dashoboard/Product/Product';
import ProductCreate from '../pages/Dashoboard/Product/ProductCreate/ProductCreate';
import ProductEdit from '../pages/Dashoboard/Product/ProductEdit/ProductEdit';
import Login from '../pages/Login/Login';
import PackingOngoingStatus from '../pages/PackingOngoingStatus/PackingOngoingStatus';

import SalaryCalculation from '../pages/Dashoboard/SalaryCalculation/SalaryCalculation';
import Employee from '../pages/Dashoboard/Employee/Employee';
import EmployeeDetails from '../pages/Dashoboard/Employee/EmployeeDetails';
import Ambassadors from '../pages/Dashoboard/Ambassadors/Ambassadors';
import CreateAmbassadors from '../pages/Dashoboard/Ambassadors/CreateAmbassadors/CreateAmbassadors';
import AddCoupon from '../pages/Dashoboard/Ambassadors/AddCoupon/AddCoupon';
import UpdateCoupon from '../pages/Dashoboard/Ambassadors/UpdateCoupon/UpdateCoupon';
import CategoryMenu from '../pages/Dashoboard/CategoryMenu/CategoryMenu';
import CreateCategoryMenu from '../pages/Dashoboard/CategoryMenu/CreateCategoryMenu/CreateCategoryMenu';
import UpdateCategoryMenu from '../pages/Dashoboard/CategoryMenu/UpdateCategoryMenu/UpdateCategoryMenu';
import AllFeedbacks from '../pages/AllFeedbacks/AllFeedbacks';
import CompanySolution from '../pages/Dashoboard/CompanySolution/CompanySolution';
import Orders from '../pages/Dashoboard/Orders/Orders';
import Permission from '../pages/Dashoboard/Permission/Permission';
import Role from '../pages/Dashoboard/Role/Role';
import RoleAssign from '../pages/Dashoboard/Role/RoleAssign/RoleAssign';
import PermissionRoute from './PermissionRoute';
import Unauthorized from '../pages/Unauthorized/Unauthorized';
import MyAttendance from '../pages/Dashoboard/MyAttendance/MyAttendance';
import EmployeeCreate from '../pages/Dashoboard/Employee/EmployeeCreate';
import OrderStatistics from '../pages/Dashoboard/OrderStatistics/OrderStatistics';
import SalesReport from '../pages/Dashoboard/Ambassadors/SalesReport/SalesReport';
import Coupon from '../pages/Dashoboard/Coupon/Coupon';
import AddNewCoupon from '../pages/Dashoboard/Coupon/AddNewCoupon';
import CustomerFeedbacks from '../pages/Dashoboard/CustomerFeedbacks/CustomerFeedbacks';
import SalaryApproval from '../pages/Dashoboard/SalaryApproval/SalaryApproval';
import SalaryApprovalEdit from '../pages/Dashoboard/SalaryApproval/SalaryApprovalEdit/SalaryApprovalEdit';
import SlikDashboard from '../pages/SlikDashboard/SlikDashboard';
import Main from '../layouts/Main';
import SalarySatistics from '../pages/Dashoboard/SalarySatistics/SalarySatistics';
import SalesLadder from '../pages/Dashoboard/SalesLadder/SalesLadder';
import SalesLadderCreate from '../pages/Dashoboard/SalesLadder/SalesLadderCreate/SalesLadderCreate';
import SalesLadderUpdate from '../pages/Dashoboard/SalesLadder/SalesLadderUpdate/SalesLadderUpdate';
import ProductTypes from '../pages/Dashoboard/ProductTypes/ProductTypes';
import ProductTypesUpdate from '../pages/Dashoboard/ProductTypes/ProductTypesUpdate/ProductTypesUpdate';
import Countries from '../pages/Dashoboard/Countries/Countries';
import CreateCountry from '../pages/Dashoboard/Countries/CreateCountry/CreateCountry';
import UpdateCountry from '../pages/Dashoboard/Countries/UpdateCountry/UpdateCountry';
import Promotion from '../pages/Dashoboard/Promotion/Promotion';
import UpdatePromotion from '../pages/Dashoboard/Promotion/UpdatePromotion/UpdatePromotion';
import CreatePromotion from '../pages/Dashoboard/Promotion/CreatePromotion/CreatePromotion';
import OrderTypes from '../pages/Dashoboard/OrderTypes/OrderTypes';
import CreateOrderType from '../pages/Dashoboard/OrderTypes/CreateOrderType/CreateOrderType';
import UpdateOrderType from '../pages/Dashoboard/OrderTypes/UpdateOrderType/UpdateOrderType';
import PrepackedLogin from '../pages/PrepackedLogin/PrepackedLogin';
import WebSearch from '../pages/Dashoboard/WebSearch/WebSearch';
import GiftCardImage from '../pages/Dashoboard/GiftCardImage/GiftCardImage';
import CreateGIftCardImage from '../pages/Dashoboard/GiftCardImage/CreateGiftCardImage/CreateGIftCardImage';
import UpdateGiftCardImage from '../pages/Dashoboard/GiftCardImage/UpdateGiftCardImage/UpdateGiftCardImage';
import CreateTranslation from '../pages/Dashoboard/Translation/CreateTranslation/CreateTranslation';
import UpdateTranslation from '../pages/Dashoboard/Translation/UpdateTranslation/UpdateTransaltion';
import InvoiceUpload from '../pages/Dashoboard/InvoiceUpload/InvoiceUpload';

const DashboardLayout = lazy(() => import('../layouts/DashboardLayout'));
const AllergicElements = lazy(() => import('../pages/Dashoboard/AllergicElements/AllergicElements'));
const Customer = lazy(() => import('../pages/Dashoboard/Customer/Customer'));
const Translation = lazy(() => import('../pages/Dashoboard/Translation/Translation'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '/', element: <Login />,

            },

            {
                path: '/prepacked/login', element: <PrepackedLogin />,

            },
            {
                path: '/401', element: <Unauthorized />
            },
            {
                path: '/packing-status', element: <PackingOngoingStatus />,
            },
            {
                path: '/get-feedbacks', element: <AllFeedbacks />,
            },
            {
                path: '/slik-dashboard', element: <SlikDashboard />
            }
        ]
    },

    {
        path: "*",
        element: <Main />,
        children: [
            {
                path: '*', element: <Login />,
            }
        ]
    },
    {
        path: '/admin/dashboard',
        element: <PrivateRoute> <PermissionRoute> <Suspense fallback={<div>Loading...</div>}><DashboardLayout /></Suspense> </PermissionRoute> </PrivateRoute>,
        children: [
            { path: '/admin/dashboard', element: <Home /> },
            { path: '/admin/dashboard/product', element: <Product /> },
            { path: '/admin/dashboard/product/create', element: <ProductCreate /> },
            { path: '/admin/dashboard/product/edit/:id', element: <ProductEdit /> },
            { path: '/admin/dashboard/allergic-elements', element: <AllergicElements /> },
            { path: '/admin/dashboard/customers', element: <Customer /> },
            { path: '/admin/dashboard/salaryCalculation', element: <SalaryCalculation /> },
            { path: '/admin/dashboard/employees', element: <Employee /> },
            { path: '/admin/dashboard/employees/details/:id', element: <EmployeeDetails /> },
            { path: '/admin/dashboard/ambassadors', element: <Ambassadors /> },
            { path: '/admin/dashboard/ambassadors/create', element: <CreateAmbassadors /> },
            { path: '/admin/dashboard/ambassadors/sales-report/:id', element: <SalesReport /> },
            { path: '/admin/dashboard/ambassadors/create-coupon/:id', element: <AddCoupon /> },
            { path: '/admin/dashboard/ambassadors/update-coupon/:id', element: <UpdateCoupon /> },
            { path: '/admin/dashboard/category-menu', element: <CategoryMenu /> },
            { path: '/admin/dashboard/category-menu/create', element: <CreateCategoryMenu /> },
            { path: '/admin/dashboard/category-menu/edit/:id', element: <UpdateCategoryMenu /> },
            { path: '/admin/dashboard/company-solution', element: <CompanySolution /> },
            { path: '/admin/dashboard/permission', element: <Permission /> },
            { path: '/admin/dashboard/role', element: <Role /> },
            { path: '/admin/dashboard/orders', element: <Orders /> },
            { path: '/admin/dashboard/role/assign/:id', element: <RoleAssign /> },
            { path: '/admin/dashboard/my-attendance', element: <MyAttendance /> },
            { path: '/admin/dashboard/employees/create', element: <EmployeeCreate /> },
            { path: '/admin/dashboard/order-statistics', element: <OrderStatistics /> },
            { path: '/admin/dashboard/coupons', element: <Coupon /> },
            { path: '/admin/dashboard/coupons/add', element: <AddNewCoupon /> },
            { path: '/admin/dashboard/customer-feedbacks', element: <CustomerFeedbacks /> },
            { path: '/admin/dashboard/salary-approval', element: <SalaryApproval /> },
            { path: '/admin/dashboard/salary-approval/edit/:id', element: <SalaryApprovalEdit /> },
            { path: '/admin/dashboard/salary-statistics', element: <SalarySatistics /> },
            { path: '/admin/dashboard/sales-ladder', element: <SalesLadder /> },
            { path: '/admin/dashboard/sales-ladder/create', element: <SalesLadderCreate /> },
            { path: '/admin/dashboard/sales-ladder/edit/:id', element: <SalesLadderUpdate /> },
            { path: '/admin/dashboard/product-types', element: <ProductTypes /> },
            { path: '/admin/dashboard/product-types/edit/:id', element: <ProductTypesUpdate /> },
            { path: '/admin/dashboard/countries', element: <Countries /> },
            { path: '/admin/dashboard/countries/create', element: <CreateCountry /> },
            { path: '/admin/dashboard/countries/edit/:id', element: <UpdateCountry /> },
            { path: '/admin/dashboard/promotions', element: <Promotion /> },
            { path: '/admin/dashboard/promotions/create', element: <CreatePromotion /> },
            { path: '/admin/dashboard/promotions/edit/:id', element: <UpdatePromotion /> },
            { path: '/admin/dashboard/order-types', element: <OrderTypes /> },
            { path: '/admin/dashboard/order-types/create', element: <CreateOrderType /> },
            { path: '/admin/dashboard/order-types/edit/:id', element: <UpdateOrderType /> },
            { path: '/admin/dashboard/web-search-histories', element: <WebSearch /> },
            { path: '/admin/dashboard/gift-cards', element: <GiftCardImage /> },
            { path: '/admin/dashboard/gift-cards/create', element: <CreateGIftCardImage /> },
            { path: '/admin/dashboard/gift-cards/edit/:id', element: <UpdateGiftCardImage /> },
            { path: '/admin/dashboard/translation', element: <Translation /> },
            { path: '/admin/dashboard/translation/create-translation', element: <CreateTranslation /> },
            { path: '/admin/dashboard/translation/edit/:id', element: <UpdateTranslation /> },
            { path: '/admin/dashboard/invoice-upload', element: <InvoiceUpload /> },
        ]
    }
])

export default router;