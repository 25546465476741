import React, { useCallback, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../components/Navbar';
import { useDropzone } from "react-dropzone";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { MaterialReactTable } from 'material-react-table';
import { Modal, Box, Typography, Button, TextField, Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from "@mui/material";

export default function InvoiceUpload() {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Progress state
    const [responseData, setResponseData] = useState(null); // Store server response
    const [openModal, setOpenModal] = useState(false);
    const [selectedLineItem, setSelectedLineItem] = useState(null);
    const [selectedLineItemSync, setSelectedLineItemSync] = useState(null);
    const [shopifyId, setShopifyId] = useState("");
    const [openModalShopify, setOpenModalShopify] = useState(false);
    const [newSalesPrice, setNewSalesPrice] = useState("");
    const [batchMagicModal, setBatchMagicModal] = useState(false);
    const [batchMagicModalData, setBatchMagicModalData] = useState(null);
    const [batchMagicModalDataSync, setBatchMagicModalDataSync] = useState(null);
    const [newWeight, setNewWeight] = useState("");
    const [newNumberOfItems, setNewNumberOfItems] = useState("");
    const [batchMagicKiloPrice, setBatchMagicKiloPrice] = useState("");
    const [newCost, setNewCost] = useState("");
    const axiosPrivate = useAxiosPrivate();

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setFile(acceptedFiles[0]); // Replace any existing file with the new one
        }
    }, []);

    const handleReset = async () => {
        setFile(null);
        setResponseData(null);
    }

    useEffect(() => {
        if (selectedLineItem) {
            setNewNumberOfItems(selectedLineItem.db_number_of_items);
            setNewWeight(selectedLineItem.db_weight);
            setNewSalesPrice(selectedLineItem.db_selling_price);
            setNewCost(selectedLineItem.db_cost);
        }
    }, [selectedLineItem])


    useEffect(() => {
        if (batchMagicModalDataSync) {
            setBatchMagicKiloPrice(batchMagicModalData?.kilo_price);
        }
    }, [batchMagicModalDataSync, batchMagicModalData]);

    const handleSync = async (item) => {
        setSelectedLineItemSync(item);
        setSelectedLineItem(null);
        setOpenModalShopify(true);
        setOpenModal(false);
    };

    const handleUpdateCandyPrice = async () => {
        if (!shopifyId) {
            alert("Please provide a Shopify ID");
            return;
        }

        // Validate inputs
        if (!newCost || !newWeight || !newSalesPrice) {
            alert("Please provide all fields for the price update.");
            return;
        }

        try {
            // Make the API request to update the candy price
            const updateResponse = await axiosPrivate.post('candy/update/candy-price', {
                cost: newCost,
                weight: newWeight,
                selling_price: newSalesPrice,
                shopify_id: shopifyId,
                number_of_items: newNumberOfItems,
            });

            const updateData = updateResponse.data?.data;

            if (updateData) {
                // If the price update is successful, update the table line items
                setResponseData((prevData) => {
                    const updatedLineItems = prevData.line_items.map((item) =>
                        item.item_number === updateData.item_number ? {
                            ...item,
                            db_cost: updateData.db_cost,
                            db_weight: updateData.db_weight,
                            db_selling_price: updateData.db_selling_price,
                            db_kilo_price: updateData.db_kilo_price,
                            db_number_of_items: updateData.db_number_of_items,
                            db_shopify_id: updateData.db_shopify_id,
                            sync: updateData.sync,
                        } : item
                    );
                    return { ...prevData, line_items: updatedLineItems };
                });

                setOpenModal(false);
            } else {
                alert("Failed to update candy price.");
            }
        } catch (error) {
            console.error("Error updating candy price", error);
            alert("Candy price update failed. Please try again.");
        } finally {
            // Close the modal and clear the inputs
            setOpenModalShopify(false);
            setShopifyId("");
            setNewSalesPrice("");
            setNewWeight("");
            setNewCost("");
        }
    };

    const updateBatchMagicPrice = async () => {
        if (!batchMagicKiloPrice) {
            alert("Please provide a kilo price");
            return;
        }

        try {
            const response = await axiosPrivate.put(`batch-magic/update/productByExternalRef/${batchMagicModalDataSync.id}`, {
                price_per_kilo: batchMagicKiloPrice,
            });

            const updatedData = response.data?.data;

            if (updatedData) {
                setBatchMagicModal(false);
                setBatchMagicModalData(null);
                setBatchMagicModalDataSync(null);
                setBatchMagicKiloPrice("");
            }
        }
        catch (error) {
            console.error("Error updating batch magic price", error);
            alert("Failed to update batch magic price. Please try again.");
        } finally {
            setBatchMagicModal(false);
            setBatchMagicModalData(null);
            setBatchMagicModalDataSync(null);
            setBatchMagicKiloPrice("");
        }
    };

    const handlebatchMagicCloseModal = () => {
        setBatchMagicModal(false);
        setBatchMagicModalData(null);
        setBatchMagicModalDataSync(null);
    };
    const loadBatchMagic = async (item) => {
        try {
            const res = await axiosPrivate.get(`batch-magic/productByExternalRef/${item.item_number}`);
            const data = res.data;

            if (data.success === false) {
                alert("Product not found in batch magic");
            }
            else {
                setBatchMagicModalDataSync(data);
                setBatchMagicModal(true);
            } // Open the modal after data is set

        } catch (error) {
            console.error("Error loading batch magic", error);
            alert("Failed to load batch magic. Please try again.");
        }
    };

    const handleBatchMagic = async (item) => {
        setBatchMagicModalData(item);
        loadBatchMagic(item);
    };

    const handleSyncSubmit = async () => {
        if (!shopifyId) {
            alert("Please provide a Shopify ID");
            return;
        }

        try {
            const response = await axiosPrivate.post('candy/external/sync', {
                shopify_id: shopifyId,
                external_ref: selectedLineItemSync?.item_number,
            });

            const updatedData = response.data?.data;

            if (updatedData && updatedData.sync) {
                setResponseData((prevData) => {
                    const updatedLineItems = prevData.line_items.map((item) =>
                        item.item_number === updatedData.item_number ? {
                            ...item,
                            sync: updatedData.sync,
                            db_kilo_price: updatedData.db_kilo_price,
                            db_number_of_items: updatedData.db_number_of_items,
                            db_cost: updatedData.db_cost,
                            db_weight: updatedData.db_weight,
                            db_shopify_id: updatedData.db_shopify_id,
                            db_selling_price: updatedData.db_selling_price,
                        } : item
                    );
                    return { ...prevData, line_items: updatedLineItems };
                });
            } else {
                alert("Sync failed. Please try again.");
            }
        } catch (error) {
            console.error("Sync API error", error);
            alert("Sync failed. Please try again.");
        } finally {
            setShopifyId("");
            setOpenModalShopify(false);
        }
    };

    const calculateKiloPrice = (row) => {
        const db_kilo_price = row.db_kilo_price;
        const invoice_kilo_price = row.kilo_price;
        const bm_kilo_price = row.bm_kilo_price;

        let isPriceDifferenceAbove5 = false;

        // Compare db_kilo_price with invoice_kilo_price
        if (db_kilo_price && invoice_kilo_price) {
            const diff = Math.abs(db_kilo_price - invoice_kilo_price);
            const percentage = (diff / db_kilo_price) * 100;
            if (percentage > 5) {
                isPriceDifferenceAbove5 = true;
            }
        }

        // Compare bm_kilo_price with invoice_kilo_price
        if (bm_kilo_price && invoice_kilo_price) {
            const diff = Math.abs(bm_kilo_price - invoice_kilo_price);
            const percentage = (diff / bm_kilo_price) * 100;
            if (percentage > 5) {
                isPriceDifferenceAbove5 = true;
            }
        }

        return isPriceDifferenceAbove5;
    };


    const getAttentionImage = (row) => {
        // Check if row is synced and compare prices if applicable
        if (row.sync && row.bm_sync) {
            // Check if either db or bm price differences are more than 5%
            if (calculateKiloPrice(row)) {
                return <img src="https://candyexpressspace.ams3.digitaloceanspaces.com/custom-file/pJdMw9tyCYfy9drNDKtoYEXEhbBCzLDtyat7mgDW.png" style={{ width: "35px", height: "35px" }} alt="not matched" />;
            }
            return <img src="https://candyexpressspace.ams3.digitaloceanspaces.com/custom-file/t6Iy9du37GedxFzCvXw9YlvlMmzOMWeKxT4zOU0j.png" style={{ width: "35px", height: "35px" }} alt="sync" />;


        } else {
            return <img src="https://candyexpressspace.ams3.digitaloceanspaces.com/custom-file/dVVNXqjUGcTRvEy8Vimf9VxzM584oB0EEZXWAZEL.png" style={{ width: "35px", height: "35px" }} alt="not synced" />;
        }
    };

    const columns = useMemo(
        () => [
            {

                accessorFn: (row) => row.sync ?
                    <img src="https://candyexpressspace.ams3.digitaloceanspaces.com/custom-file/0ZtjIg0uQXMlXdW7wkbfJrLDbgLCIRyxzqKQf9Jv.png" style={
                        {
                            width: "35px",
                            height: "35px",
                        }
                    } alt="synced" />
                    : <img src="https://candyexpressspace.ams3.digitaloceanspaces.com/custom-file/dVVNXqjUGcTRvEy8Vimf9VxzM584oB0EEZXWAZEL.png" style={{
                        width: "35px",
                        height: "35px"
                    }} alt="not synced" />,
                header: "Product Sync",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.item_number,
                header: "Item Number",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.product_name,
                header: "Product Name",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.sync ? row.db_kilo_price.toFixed(2) : 'Not Synced',
                header: "Shopify Kilo Price",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.bm_sync ? row.bm_kilo_price.toFixed(2) : 'Not Synced',
                header: "Batch Magic Kilo Price",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => row.kilo_price,
                header: "Invoice Kilo Price",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },
            {
                accessorFn: (row) => getAttentionImage(row),
                header: "Attention",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
                Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>
            },

            {
                accessorFn: (row) =>
                (<>
                    {row.sync === true && <Button
                        variant="contained" color="primary"
                        onClick={() => handleOpenModal(row)}
                    >
                        <i className="fab fa-shopify"></i>
                    </Button>}
                    {
                        <Button
                            variant="contained"
                            color="success"
                            style={{
                                marginLeft: "10px",
                                marginRight: "10px"
                            }}
                            onClick={() => handleBatchMagic(row)}
                        >
                            <i className="fas fa-magic"></i>
                        </Button>
                    }
                    {row.sync !== true && <Button variant="contained" color="secondary" onClick={() => handleSync(row)}>
                        <i class="fa-solid fa-rotate"></i>
                    </Button>}
                </>),
                id: "action",
                header: "Action",
                muiTableHeadCellProps: { sx: { color: "#9C6EAA" } },
            }
        ],
        [],
    );

    const handleOpenModal = (item) => {
        setSelectedLineItem(item);
        setOpenModal(true);
        setShopifyId(item?.db_shopify_id);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedLineItem(null);

    };

    const handleCloseShoipfyModal = () => {
        setOpenModalShopify(false);
        setSelectedLineItemSync(null);
        setShopifyId("");
    };


    const handleFileUpload = () => {
        if (file) {
            setIsUploading(true); // Start loading animation
            setUploadProgress(0); // Reset progress
            // Create a FormData object
            const formData = new FormData();
            formData.append("invoice", file); // "invoice" matches the server's expected key

            // Simulate upload process
            const uploadInterval = setInterval(() => {
                setUploadProgress((prevProgress) => {
                    if (prevProgress >= 80) {
                        clearInterval(uploadInterval); // Stop progress at 80%
                        return prevProgress;
                    }
                    return prevProgress + 15; // Increment by 20%
                });
            }, 5000); // Increase every 5 seconds

            // Make the POST request using Axios
            axiosPrivate.post('invoice/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Important for FormData
                },
            })
                .then((response) => {
                    setResponseData(response?.data?.data); // Save server response
                    setUploadProgress(100); // Complete progress bar
                })
                .catch((error) => {
                    alert('File upload failed');
                })
                .finally(() => {
                    setIsUploading(false); // Stop loading animation
                });
        }
    };

    const removeFile = () => {
        setFile(null);
        setUploadProgress(0); // Reset progress
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "application/pdf", // Only accept PDF files
        maxFiles: 1, // Limit to 1 file at a time
    });

    return (
        <>
            <Navbar
                title="Invoice Upload"
            />

            {responseData && <Button variant="contained" color="success" style={{ margin: "20px", marginRight: "300px", float: "right" }} onClick={() => handleReset()}>
                Upload new invoice
            </Button>}

            {!responseData && (
                <div className="container">
                    {!file && (
                        <div
                            {...getRootProps()}
                            style={{
                                border: "2px dashed #ccc",
                                padding: "200px",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "100px",
                                textAlign: "center",
                                cursor: "pointer",
                                backgroundColor: isDragActive ? "#f0f0f0" : "#fff",
                            }}
                        >
                            <input {...getInputProps()} type="file" accept=".pdf" />
                            <p>
                                {isDragActive
                                    ? "Drop the file here..."
                                    : "Drag & drop a PDF file here or click to upload"}
                            </p>
                        </div>
                    )}
                    {file && (
                        <div className="mt-5">
                            {!isUploading && <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    margin: "10px 0",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            >
                                <span>{file.name}</span>
                                <button className="btn btn-danger" onClick={removeFile}>
                                    Remove
                                </button>
                            </div>}
                            {isUploading && (
                                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    <div className="progress">
                                        <div
                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            style={{ width: `${uploadProgress}%` }}
                                            aria-valuenow={uploadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {uploadProgress}%
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div>
                                <button
                                    onClick={handleFileUpload}
                                    className="btn btn-primary"
                                    disabled={isUploading}
                                >
                                    {isUploading ? (
                                        <span>
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Uploading...
                                        </span>
                                    ) : (
                                        "Upload"
                                    )}
                                </button>
                            </div>

                        </div>
                    )}
                </div>
            )}
            {responseData != null && (
                <div

                    style={{ marginTop: "50px", marginLeft: "80px", marginRight: "80px", marginBottom: "50px" }}
                >
                    <h5>Invoice Number: {responseData?.invoice_number}</h5>
                    {/* <h5>Invoice Date: {responseData?.date}</h5>
                    <h5>Invoice Total Amount: {responseData?.total_amount}</h5> */}

                    <MaterialReactTable
                        columns={columns}
                        data={responseData?.line_items || []}
                    />
                </div >
            )
            }

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="line-item-modal-title"
                aria-describedby="line-item-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 900,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography
                        id="line-item-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ marginBottom: 2, textAlign: "center" }}
                    >
                        Shopify Price Update
                    </Typography>


                    {selectedLineItem ? (
                        <>
                            <Typography
                                id="line-item-modal-description"
                                sx={{ marginBottom: 2, textAlign: "left" }}
                            >
                                Product Name: {selectedLineItem?.product_name}<br />
                            </Typography>


                            <Typography
                                id="line-item-modal-description"
                                sx={{ marginBottom: 2, textAlign: "left" }}
                            >
                                Item Number: {selectedLineItem?.item_number}<br />

                            </Typography>

                            <TableContainer>
                                <Table>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>
                                                Info
                                            </strong></TableCell>
                                            <TableCell><strong>Old Price </strong></TableCell>
                                            <TableCell><strong>Save New Price</strong></TableCell>
                                            <TableCell><strong>Price Suggestion</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Amount
                                            </TableCell>

                                            <TableCell>{selectedLineItem?.db_number_of_items}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Amount"
                                                    value={newNumberOfItems}
                                                    onChange={(e) => setNewNumberOfItems(e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>

                                        </TableRow>

                                        <TableRow>
                                            <TableCell>
                                                Weight
                                            </TableCell>
                                            <TableCell>{selectedLineItem?.db_weight}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Weight"
                                                    value={newWeight}
                                                    onChange={(e) => setNewWeight(e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Selling Price
                                            </TableCell>
                                            <TableCell>{selectedLineItem?.db_selling_price}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Selling Price"
                                                    value={newSalesPrice}
                                                    onChange={(e) => setNewSalesPrice(e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    newCost ?
                                                        ((parseFloat(newCost) + (parseFloat(newCost) * 1.6)) * 1.25).toFixed(2)
                                                        : 0
                                                }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Cost Price
                                            </TableCell>

                                            <TableCell>{selectedLineItem?.db_cost}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    label="Cost"
                                                    value={newCost}
                                                    onChange={(e) => setNewCost(e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <strong>  Calculated Kilo Cost Price (Shopify)</strong>
                                            </TableCell>
                                            <TableCell>{
                                                selectedLineItem?.db_kilo_price.toFixed(2)
                                            }</TableCell>
                                            <TableCell>{
                                                newCost && newWeight ?
                                                    (((newCost / newWeight) * 1000) - 25.97).toFixed(2) : 0
                                            }</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>

                                            <TableCell>
                                                <strong>Calculated Customer Kilo Price  </strong>
                                            </TableCell>
                                            <TableCell>{
                                                (((selectedLineItem?.db_selling_price / selectedLineItem?.db_weight) * 1000) - 25.97).toFixed(2)
                                            }</TableCell>

                                            <TableCell>{
                                                newSalesPrice && newWeight ?
                                                    (((newSalesPrice / newWeight) * 1000) - 25.97).toFixed(2) : 0
                                            }</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <strong>
                                                    Calculated Kilo Cost Price (Invoice)
                                                </strong>
                                            </TableCell>
                                            <TableCell>{
                                                selectedLineItem?.kilo_price
                                            }</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>


                    ) : (
                        <Typography>No details available.</Typography>
                    )}

                    <Button
                        onClick={handleUpdateCandyPrice}
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 2 }}
                    >
                        Update Price
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, ml: 2 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>


            <Modal
                open={openModalShopify}
                onClose={handleCloseShoipfyModal}
                aria-labelledby="sync-modal-title"
                aria-describedby="sync-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="sync-modal-title" variant="h6" component="h2">
                        Sync Product
                    </Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Shopify ID"
                        value={shopifyId}
                        onChange={(e) => setShopifyId(e.target.value)}
                    />
                    <Button
                        onClick={handleSyncSubmit}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Sync
                    </Button>

                    <Button
                        onClick={handleCloseShoipfyModal}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, ml: 2 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            {
                batchMagicModal &&
                <Modal
                    open={batchMagicModal}
                    onClose={handlebatchMagicCloseModal}
                    aria-labelledby="sync-modal-title"
                    aria-describedby="sync-modal-description"
                >
                    {
                        batchMagicModalDataSync ? (
                            <>
                                <Box sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    boxShadow: 24,
                                    width: 600,
                                    bgcolor: "background.paper",
                                    border: "2px solid #000",
                                    p: 4,
                                }}>
                                    <Typography id="sync-modal-title" variant="h6" component="h2">
                                        Batch Magic Data
                                    </Typography>
                                    <Typography>
                                        <strong>Batch Magic Product Name:</strong> {batchMagicModalDataSync?.name}
                                    </Typography>
                                    <Typography>
                                        <strong>Invoice Price per Kilo:</strong> {batchMagicModalData?.kilo_price}
                                    </Typography>


                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><strong> Info</strong> </TableCell>
                                                    <TableCell><strong>Old Price</strong></TableCell>
                                                    <TableCell><strong>Save New Price</strong></TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        Batch Magic Price per Kilo
                                                    </TableCell>
                                                    <TableCell>
                                                        {batchMagicModalDataSync?.price_per_kilo}
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            fullWidth
                                                            margin="normal"
                                                            label="Kilo Price"
                                                            style={{ marginBottom: "10px" }}
                                                            value={batchMagicKiloPrice}
                                                            onChange={(e) => setBatchMagicKiloPrice(e.target.value)}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>



                                    <Button
                                        onClick={updateBatchMagicPrice}
                                        variant="contained"
                                        color="secondary"
                                        sx={{ mt: 2 }}
                                    >
                                        Update Price
                                    </Button>
                                    <Button
                                        onClick={handlebatchMagicCloseModal}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2, ml: 2 }}
                                    >
                                        Close
                                    </Button>
                                </Box>

                            </>
                        ) : (
                            <Typography>No data available</Typography>
                        )
                    }
                </Modal>
            }
        </>
    );
}
