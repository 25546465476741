import Navbar from '../../../../components/Navbar';
import { CircularProgress, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function UpdateTranslation() {
    const axiosPrivate = useAxiosPrivate();
    const { register, handleSubmit, formState: { errors }, setValue, setError, clearErrors, watch } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();
    const keyValue = watch("key"); // Watch the key field for changes

    // Fetch existing translation data
    const fetchTranslation = async () => {
        const response = await axiosPrivate.get(`translation/show/${params.id}`);
        return response;
    };

    const { data: translation } = useQuery({
        queryKey: ['get-Translation', params.id],
        queryFn: fetchTranslation
    });

    // Populate the form with fetched data
    useEffect(() => {
        if (translation?.data?.data) {
            Object.keys(translation.data.data).forEach((key) => {
                setValue(key, translation.data.data[key]);
            });
        }
    }, [translation, setValue]);

    // AI Translation Handler
    const handleTranslationWithAI = async () => {
        setIsLoading(true);
        const response = await axiosPrivate.post(`translation/generate-translation`, { key: keyValue, en: watch("en") });

        if (response?.data?.success) {
            setValue("en", response.data.data.en);
            setValue("da", response.data.data.da);
            setValue("se", response.data.data.se);
        }
        setIsLoading(false);
    };

    // Update translation data
    const postData = async (data) => {
        const updatedData = {
            en: data.en,
            da: data.da,
            se: data.se
        };
        const response = axiosPrivate.put(`translation/update/${params.id}`, updatedData);
        return response;
    };

    const { mutate } = useMutation(postData, {
        onSuccess: (successData) => {
            if (successData?.data?.success) {
                setTimeout(() => {
                    window.location.href = '/admin/dashboard/translation';
                }, 3000);
            }
        },
        onError: (error) => {
            setIsLoading(false);
            console.error("Error updating translation", error);
        }
    });

    const onSubmit = (data) => {
        setIsLoading(true);
        mutate(data);
    };

    return (
        <>
            <Navbar title="Update Translation" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container my-5">
                    <div className="card ps-5">
                        <Grid container spacing={2}>
                            {/* Column 1: Key */}
                            <Grid item xs={6}>
                                <div className="form-group">
                                    <label htmlFor="key">Key</label>
                                    <textarea
                                        id="key"
                                        className="form-control"
                                        placeholder="Enter Key"
                                        {...register("key", {
                                            required: "Key is required."
                                        })}
                                        disabled
                                        rows="8"
                                    />
                                    {errors.key && (
                                        <p className="text-danger">{errors.key.message}</p>
                                    )}
                                </div>
                            </Grid>

                            {/* Column 2: Translations */}
                            <Grid item xs={6}>
                                {/* English */}
                                <div className="form-group">
                                    <label htmlFor="key_en">English</label>
                                    <textarea
                                        id="key_en"
                                        className="form-control"
                                        placeholder="Enter English"
                                        {...register("en", {
                                            required: "English translation is required.",
                                            onChange: () => clearErrors("en"),
                                        })}
                                        rows="2"
                                    />
                                    {errors.en && (
                                        <p className="text-danger">{errors.en.message}</p>
                                    )}
                                </div>

                                {/* Danish */}
                                <div className="form-group mt-3">
                                    <label htmlFor="key_da">Danish</label>
                                    <textarea
                                        id="key_da"
                                        className="form-control"
                                        placeholder="Enter Danish"
                                        {...register("da", {
                                            onChange: () => clearErrors("da"),
                                        })}
                                        rows="2"
                                    />
                                    {errors.da && (
                                        <p className="text-danger">{errors.da.message}</p>
                                    )}
                                </div>

                                {/* Swedish */}
                                <div className="form-group mt-3">
                                    <label htmlFor="key_se">Swedish</label>
                                    <textarea
                                        id="key_se"
                                        className="form-control"
                                        placeholder="Enter Swedish"
                                        {...register("se", {
                                            onChange: () => clearErrors("se"),
                                        })}
                                        rows="2"
                                    />
                                    {errors.se && (
                                        <p className="text-danger">{errors.se.message}</p>
                                    )}
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "40px" }}>
                                {/* Submit Button */}
                                <button
                                    className="btn btn-success text-white"
                                    disabled={isLoading || Object.keys(errors).length > 0}
                                    type="submit"
                                >
                                    Submit
                                </button>

                                {/* Generate Translation Button */}
                                <button
                                    className="btn btn-info text-white ms-3"
                                    type="button"
                                    onClick={async () => {
                                        const enError = !watch("en") ? "English translation is required." : null;

                                        if (enError) {
                                            setError("en", { message: enError });
                                            return;
                                        }

                                        setIsLoading(true);
                                        try {
                                            await handleTranslationWithAI();
                                        } finally {
                                            setIsLoading(false);
                                        }
                                    }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress color="success" /> : "Generate Translation With AI"}
                                </button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </>
    );
}
